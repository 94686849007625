import React from "react";
import lunch_meet from "../assests/gifs/lunch_meet.jpg";
import gorgeous_feets from "../assests/gifs/gorgeous_feets.jpg";
import give_me_hug from "../assests/gifs/give_me_hug.jpg";
import sexiest_lips from "../assests/gifs/sexiest_lips.jpg";
import preety_dress from "../assests/gifs/preety_dress.jpg";
import want_to_meet_you from "../assests/gifs/want_to_meet_you.jpg";

import beautiful_nails from "../assests/gifs/beautiful_nails.jpg";
import roses_for_you from "../assests/gifs/roses_for_you.jpg";
import your_skin_goreous from "../assests/gifs/your_skin_goreous.jpg";
import coffee_meet from "../assests/gifs/coffee_meet.jpg";
import release from "../assests/gifs/release.jpg";
import perfect_glass_figure from "../assests/gifs/perfect_glass_figure.jpg";
import dare from "../assests/gifs/dare.jpg";
import neckace_for_you from "../assests/gifs/neckace_for_you.jpg";
import sexy from "../assests/gifs/sexy.jpg";
import kiss_for_you from "../assests/gifs/kiss_for_you.jpg";
import you_look_stunning from "../assests/gifs/you_look_stunning.jpg";

import love_your_nails from "../assests/gifs/love_your_nails.jpg";
import show_me_your_feet from "../assests/gifs/show_me_your_feet.jpg";
import sexiest_naval from "../assests/gifs/sexiest_naval.jpg";
import i_love_you from "../assests/gifs/i_love_you.jpg";
import love_your_look from "../assests/gifs/love_your_look.jpg";
import wear_saree from "../assests/gifs/wear_saree.jpg";
import beautiful_cheeks from "../assests/gifs/beautiful_cheeks.jpg";
import flower_for_you from "../assests/gifs/flower_for_you.jpg";
import blow_kiss from "../assests/gifs/blow_kiss.jpg";
import lovely_vocie from "../assests/gifs/lovely_vocie.jpg";
import wear_earrings from "../assests/gifs/wear_earrings.jpg";

import show_me_sexy_expressions from "../assests/gifs/show_me_sexy_expressions.jpg";
import bite_your_lip_sensually from "../assests/gifs/bite_your_lip_sensually.jpg";
import yes from "../assests/gifs/yes.jpg";
import skin_like_butter from "../assests/gifs/skin_like_butter.jpg";
import perfume_for_you from "../assests/gifs/perfume_for_you.jpg";
import smile_for_me from "../assests/gifs/smile_for_me.jpg";
import hugs_to_you from "../assests/gifs/hugs_to_you.jpg";

import you_do_best from "../assests/gifs/you_do_best.jpg";
import indian_kylie_jenner from "../assests/gifs/indian_kylie_jenner.jpg";
import sing_a_song from "../assests/gifs/sing_a_song.jpg";
import i_am_here from "../assests/gifs/i_am_here.jpg";
import hello_gorgeous from "../assests/gifs/hello_gorgeous.jpg";
import sexiest_lips_1 from "../assests/gifs/sexiest_lips_1.jpg";
import freeze from "../assests/gifs/freeze.jpg";
import beautiful_hair from "../assests/gifs/beautiful_hair.jpg";
import gorgeous_hands from "../assests/gifs/gorgeous_hands.jpg";
import apply_lipstick from "../assests/gifs/apply_lipstick.jpg";
import hottest_cleavage from "../assests/gifs/hottest_cleavage.jpg";
import thumbsup from "../assests/gifs/thumbsup.jpg";
import hi_babe from "../assests/gifs/hi_babe.jpg";
import indian_kim_kardashian_1 from "../assests/gifs/indian_kim_kardashian_1.jpg";
import my_baby_doll from "../assests/gifs/my_baby_doll.jpg";
import no from "../assests/gifs/no.jpg";
import video_call_with_me_2 from "../assests/gifs/video_call_with_me_2.jpg";
import snow_while from "../assests/gifs/snow_while.jpg";
import wink_me from "../assests/gifs/wink_me.jpg";
import peaches_and_milk from "../assests/gifs/peaches_and_milk.jpg";
import video_call_with_me_1 from "../assests/gifs/video_call_with_me_1.jpg";
import truth from "../assests/gifs/truth.jpg";
import diamond_ring from "../assests/gifs/diamond_ring.jpg";
import stunning_eyes from "../assests/gifs/stunning_eyes.jpg";
import be_my_girlfriend from "../assests/gifs/be_my_girlfriend.jpg";
import i_like_you from "../assests/gifs/i_like_you.jpg";
import beautiful_moves from "../assests/gifs/beautiful_moves.jpg";

export const GifsData = [
  { gif_id: 1, name: "stunning_eyes.jpg", noOfGems: 399, image: stunning_eyes },
  {
    gif_id: 1,
    name: "gorgeous_hands.jpg",
    noOfGems: 299,
    image: gorgeous_hands,
  },
  {
    gif_id: 1,
    name: "show_me_your_feet.jpg",
    noOfGems: 699,
    image: show_me_your_feet,
  },
  { gif_id: 1, name: "sexiest_lips.jpg", noOfGems: 399, image: sexiest_lips },
  {
    gif_id: 1,
    name: "beautiful_nails.jpg",
    noOfGems: 299,
    image: beautiful_nails,
  },
  {
    gif_id: 1,
    name: "beautiful_hair.jpg",
    noOfGems: 299,
    image: beautiful_hair,
  },
  {
    gif_id: 1,
    name: "gorgeous_feets.jpg",
    noOfGems: 299,
    image: gorgeous_feets,
  },
  { gif_id: 1, name: "wear_saree.jpg", noOfGems: 249, image: wear_saree },
  { gif_id: 1, name: "sexy.jpg", noOfGems: 199, image: sexy },
  {
    gif_id: 1,
    name: "want_to_meet_you.jpg",
    noOfGems: 999,
    image: want_to_meet_you,
  },
  { gif_id: 1, name: "coffee_meet.jpg", noOfGems: 425000, image: coffee_meet },
  { gif_id: 1, name: "lunch_meet.jpg", noOfGems: 425000, image: lunch_meet },
  { gif_id: 1, name: "preety_dress.jpg", noOfGems: 39, image: preety_dress },
  {
    gif_id: 1,
    name: "love_your_look.jpg",
    noOfGems: 99,
    image: love_your_look,
  },
  { gif_id: 1, name: "i_am_here.jpg", noOfGems: 39, image: i_am_here },
  { gif_id: 1, name: "sing_a_song.jpg", noOfGems: 99, image: sing_a_song },
  { gif_id: 1, name: "dare.jpg", noOfGems: 39, image: dare },
  { gif_id: 1, name: "truth.jpg", noOfGems: 39, image: truth },
  { gif_id: 1, name: "no.jpg", noOfGems: 39, image: no },
  { gif_id: 1, name: "yes.jpg", noOfGems: 39, image: yes },
  { gif_id: 1, name: "lovely_vocie.jpg", noOfGems: 39, image: lovely_vocie },
  {
    gif_id: 1,
    name: "beautiful_moves.jpg",
    noOfGems: 69,
    image: beautiful_moves,
  },
  { gif_id: 1, name: "wear_earrings.jpg", noOfGems: 99, image: wear_earrings },
  {
    gif_id: 1,
    name: "apply_lipstick.jpg",
    noOfGems: 69,
    image: apply_lipstick,
  },
  { gif_id: 1, name: "smile_for_me.jpg", noOfGems: 39, image: smile_for_me },
  { gif_id: 1, name: "thumbsup.jpg", noOfGems: 39, image: thumbsup },
  { gif_id: 1, name: "hi_babe.jpg", noOfGems: 39, image: hi_babe },
  {
    gif_id: 1,
    name: "hello_gorgeous.jpg",
    noOfGems: 39,
    image: hello_gorgeous,
  },
  {
    gif_id: 1,
    name: "love_your_nails.jpg",
    noOfGems: 199,
    image: love_your_nails,
  },
  { gif_id: 1, name: "i_love_you.jpg", noOfGems: 499, image: i_love_you },
  { gif_id: 1, name: "give_me_hug.jpg", noOfGems: 499, image: give_me_hug },
  {
    gif_id: 1,
    name: "neckace_for_you.jpg",
    noOfGems: 399,
    image: neckace_for_you,
  },
  {
    gif_id: 1,
    name: "your_skin_goreous.jpg",
    noOfGems: 399,
    image: your_skin_goreous,
  },
  {
    gif_id: 1,
    name: "perfect_glass_figure.jpg",
    noOfGems: 299,
    image: perfect_glass_figure,
  },
  { gif_id: 1, name: "wink_me.jpg", noOfGems: 299, image: wink_me },

  { gif_id: 1, name: "roses_for_you.jpg", noOfGems: 299, image: roses_for_you },
  { gif_id: 1, name: "snow_while.jpg", noOfGems: 199, image: snow_while },
  {
    gif_id: 1,
    name: "perfume_for_you.jpg",
    noOfGems: 199,
    image: perfume_for_you,
  },
  {
    gif_id: 1,
    name: "flower_for_you.jpg",
    noOfGems: 199,
    image: flower_for_you,
  },
  { gif_id: 1, name: "hugs_to_you.jpg", noOfGems: 499, image: hugs_to_you },
  { gif_id: 1, name: "you_do_best.jpg", noOfGems: 99, image: you_do_best },
  { gif_id: 1, name: "i_like_you.jpg", noOfGems: 99, image: i_like_you },
  { gif_id: 1, name: "my_baby_doll.jpg", noOfGems: 99, image: my_baby_doll },
  {
    gif_id: 1,
    name: "peaches_and_milk.jpg",
    noOfGems: 99,
    image: peaches_and_milk,
  },
  {
    gif_id: 1,
    name: "skin_like_butter.jpg",
    noOfGems: 69,
    image: skin_like_butter,
  },
  { gif_id: 1, name: "diamond_ring.jpg", noOfGems: 999, image: diamond_ring },
  { gif_id: 1, name: "blow_kiss.jpg", noOfGems: 99, image: blow_kiss },

  { gif_id: 1, name: "kiss_for_you.jpg", noOfGems: 99, image: kiss_for_you },
  {
    gif_id: 1,
    name: "be_my_girlfriend.jpg",
    noOfGems: 299,
    image: be_my_girlfriend,
  },
  {
    gif_id: 1,
    name: "you_look_stunning.jpg",
    noOfGems: 199,
    image: you_look_stunning,
  },
  {
    gif_id: 1,
    name: "indian_kim_kardashian.jpg",
    noOfGems: 199,
    image: indian_kim_kardashian_1,
  },
  {
    gif_id: 1,
    name: "indian_kylie_jenner.jpg",
    noOfGems: 99,
    image: indian_kylie_jenner,
  },
  { gif_id: 1, name: "release.jpg", noOfGems: 199, image: release },
  { gif_id: 1, name: "freeze.jpg", noOfGems: 199, image: freeze },
  {
    gif_id: 1,
    name: "bite_your_lip_sensually.jpg",
    noOfGems: 399,
    image: bite_your_lip_sensually,
  },
  {
    gif_id: 1,
    name: "show_me_sexy_expressions.jpg",
    noOfGems: 499,
    image: show_me_sexy_expressions,
  },

  {
    gif_id: 1,
    name: "hottest_cleavage.jpg",
    noOfGems: 399,
    image: hottest_cleavage,
  },
  {
    gif_id: 1,
    name: "beautiful_cheeks.jpg",
    noOfGems: 199,
    image: beautiful_cheeks,
  },
  {
    gif_id: 1,
    name: "sexiest_lips_1.jpg",
    noOfGems: 199,
    image: sexiest_lips_1,
  },
  { gif_id: 1, name: "sexiest_naval.jpg", noOfGems: 199, image: sexiest_naval },
];

export const DisplayImage = ({ src, style }) => (
  <img src={src.image} style={{ ...style }} />
);

import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ModelProfilePicture from "../../assests/incomingcall.jpg";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";
import Diamands from "../../assests/diamands.png";
import Spinners from "../../components/Spinner/Spinners";
import Spinner2 from "../../components/Spinner/Spinner2";

function MyPurchaseOrder() {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE))
  );
  const [isLoading, setIsLoading] = useState(false);

  const [orders, setOredrs] = useState([]);
  useEffect(() => {
    window.scrollTo({ behavior: "smooth" });
    getOrder();
  }, []);

  const getOrder = () => {
    setIsLoading(true);

    let UserOrders = [];
    const q1 = query(
      collection(db, process.env.REACT_APP_ORDERS_COLLECTION),
      where("uid", "==", user.idUser)
    );
    getDocs(q1).then((result) => {
      result.forEach((doc) => {
        UserOrders.push(doc.data());
      });
      setOredrs(UserOrders);
      setIsLoading(false);
    });
  };
  return (
    <>
      {isLoading ? <Spinner2 /> : null}
      {orders?.length === 0 && !isLoading && (
        <Typography
          style={{
            position: "fixed",
            top: "50%",
            width: "100vw",
            textAlign: "center",
            left: 0,
          }}
        >
          No records found
        </Typography>
      )}
      {orders &&
        orders.map((singleOrder) => {
          let dateTimeString = `${singleOrder.orderedOn
            .toDate()
            .toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })} ${new Date(singleOrder.orderedOn.toDate()).toLocaleDateString(
            "en-US",
            { year: "numeric", month: "long", day: "numeric" }
          )}`;
          return (
            <Box
              className="porder"
              style={{ backgroundColor: "var(--red)", marginTop: "4em" }}
            >
              <Box className="orderm">
                <Box style={{ width: "120px", height: "120px" }}>
                  <img alt="" src={ModelProfilePicture} className="postimg" />
                </Box>
                <Box className="descrption">
                  <div style={{ display: "flex", justifyContent: "start" }}>
                    <Typography className="typo">
                      {process.env.REACT_APP_MY_PURCHASES_ORDER_PAID_TEXT}
                      &nbsp;: {singleOrder.noOfGems}
                    </Typography>
                    <img
                      alt=""
                      src={Diamands}
                      style={{
                        height: "20px",
                        width: "25px",
                        marginLeft: "10px",
                      }}
                    />
                  </div>
                  <Typography className="typo">{singleOrder.pName}</Typography>
                  <Typography className="typo">
                    {process.env.REACT_APP_MY_PURCHASES_ORDER_ON_TEXT}&nbsp;:{" "}
                    {dateTimeString}
                  </Typography>
                  <Typography className="typo">
                    {process.env.REACT_APP_MY_PURCHASES_ORDER_STATUS_TEXT}{" "}
                    &nbsp;: {singleOrder.orderStatus}
                  </Typography>
                </Box>
              </Box>
            </Box>
          );
        })}
    </>
  );
}

export default MyPurchaseOrder;

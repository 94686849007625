import { Box } from "@mui/material";
import React, { useState } from "react";
import BackGround from "../../assests/videoCall.jpg";
import Logo from "../../assests/logo.png";
import "../../App.scss";
import "./PreLogin.scss";
import { useNavigate } from "react-router";
import Register from "../Register/Register";
import SendOTP from "../Send OTP/SendOTP";

const PreLogin = () => {
  let [count, setCount] = useState(true);

  const onModuleChange = () => {
    setCount(!count);
  };
  return (
    <Box className="general">
      <Box className="regisercss">
        <Box
          className="register-container"
          style={{
            backgroundImage: `url(${BackGround})`,
          }}
        >
          <img src={Logo} />
          {count ? (
            <Register change={() => onModuleChange()} />
          ) : (
            <SendOTP change={() => onModuleChange()} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PreLogin;

import {
  Box,
  Button,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from "react";
import BackGround from "../../assests/ic_logo.jpg";
import Logo from "../../assests/logo.png";
import "../../App.scss";
import { useLocation } from "react-router";

const SendOTP = (props) => {
  const [count, setCount] = useState(60);
  const [OTP, setOTP] = useState("");
  const location= useLocation();
  if (count > 0) {
    setTimeout(() => {
      setCount(count - 1);
    }, 1000);
  }
  const proceed = () => {
    // confirmationResult.confirm(OTP).then((result) => {
    //   const user = result.user;
    // }).catch((error) => {
    // });
  }
  return (
    <Box
      className="register-container"
      style={{
        backgroundImage: `url(${BackGround})`,
      }}
    >
      <img src={Logo} />
      <Typography className="upper-text">
        We have sent you verification code to
      </Typography>
      <Typography className="upper-text">+91 9999999999</Typography>
      <Typography className="upper-text upper-text1">
        Please Enter the code to verify and proceed further.
      </Typography>
      <TextField
        placeholder="Enter OTP"
        variant="outlined"
        className="textfield"
        onChange={(e)=>{
            setOTP(e.target.value)
        }}
        focused={false}
        inputProps={{ maxLength: 6 }}
      />
      {count !== 0 ? (
        <>
        <Typography className="resend-otp-text">
          Resend OTP in {count}
        </Typography>
        <Typography className="upper-text">+91 9999999999</Typography>
        <Typography className="upper-text upper-text1 ">
          Please Enter the code to verify and proceed further.
        </Typography>
        <TextField
          placeholder="Enter OTP"
          variant="outlined"
          className="textfield"
          focused={false}
          inputProps={{ maxLength: 6 }}
        />
        </>
      ) : null}
        {count == 0 ? (
          <Button
            variant="contained"
            className="register-screen-button typography-loginpage loginpage-button"
            color="primary"
            onClick={() => setCount(60)}
          >
            Resend OTP
          </Button>
        ) :null}
      <Button
        variant="contained"
        className="register-screen-button typography-loginpage loginpage-button"
        color="primary"
        onClick={proceed}
      >
        Proceed
      </Button>

      <Button
        variant="contained"
        className="register-screen-button typography-loginpage loginpage-button"
        color="primary"
        onClick={() => props.change()}
      >
        Edit Details
      </Button>
    </Box>
  );
};

export default SendOTP;

import React, { useEffect, useState } from "react";

const Timer = (props) => {
  const [isActive, setIsActive] = useState(true);
  const [isPaused, setIsPaused] = useState(false);
  const [time, setTime] = useState(0);

  useEffect(() => {
    let interval = null;

    if (isActive && !isPaused) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 1000);
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isActive, isPaused]);

  useEffect(() => {
    props.time(formatTime());
  }, [time]);

  const formatTime = () => {
    return `${("0" + Math.floor((time / 3600000) % 24)).slice(-2)}:${(
      "0" + Math.floor((time / 60000) % 60)
    ).slice(-2)}:${("0" + Math.floor((time / 1000) % 60)).slice(-2)}`;
  };

  return (
    <div className="timer">
      <span className="digits" style={{ fontFamily: "var(-font)" }}>
        {formatTime()}
      </span>
    </div>
  );
};

export default Timer;

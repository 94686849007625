import { Box, Button, Grid, Typography } from "@mui/material";
import VideocamIcon from "@mui/icons-material/Videocam";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React, { useEffect, useState } from "react";
import logo from "../../../assests/logo.png";
import jems from "../../../component/Images/jems.png";
import "../MainHead/Mainhead.scss";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  collection,
  onSnapshot,
  query,
  where,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../../../firebaseConfig/firebaseConfig";
function MainBackhead() {
  const navigate = useNavigate();
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE))
  );
  const location = useLocation();
  const videocallpage = () => {
    navigate("/mainback/uservideocall", { state: { muted: false } });
  };
  const [wallet, setWallet] = useState();
  const getData = () => {
    const q = query(
      collection(db, process.env.REACT_APP_WALLET_COLLECTION_NAME),
      where(
        "uid",
        "==",
        JSON.parse(
          localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE)
        ).idUser
      )
    );
    onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        setWallet(doc.data().balance);
        localStorage.setItem(
          process.env.REACT_APP_WALLET_LOCALSTORAGE,
          JSON.stringify(doc.data())
        );
      });
    });
  };
  useEffect(() => {
    getData();

    const q = query(
      collection(db, process.env.REACT_APP_WALLET_COLLECTION_NAME),
      where(
        "uid",
        "==",
        JSON.parse(
          localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE)
        ).idUser
      )
    );
    onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        setWallet(doc.data());
        localStorage.setItem(
          process.env.REACT_APP_WALLET_LOCALSTORAGE,
          JSON.stringify(doc.data())
        );
      });
    });
  }, []);

  return (
    <Box className="hh">
      <Box className="header">
        <Grid container className="headbox">
          <Grid item xs={1.5}>
            <Box
              className="menubtn"
              onClick={() =>
                location.pathname == "/mainback/rechargepage"
                  ? navigate("/userhomepage/home")
                  : navigate(-1)
              }
            >
              <ArrowBackIcon style={{ color: "var(--white)" }} />
            </Box>
          </Grid>
          <Grid item xs={5}>
            <img alt="" src={logo} className="adminLogo" />
          </Grid>
          <Grid
            item
            xs={2.5}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            onClick={() => navigate("/back/wallet")}
          >
            <img alt="" src={jems} className="jemsimg" />
            <Typography variant="body2" id="jemstext">
              {wallet?.balance}
            </Typography>
          </Grid>
          {location.pathname !== "/mainback/uservideocall" && (
            <Grid item xs={3}>
              <Button
                id="videobtn"
                variant="contained"
                startIcon={<VideocamIcon />}
                onClick={videocallpage}
              >
                CALL
              </Button>
            </Grid>
          )}
        </Grid>
        <Box className="cmline"></Box>
      </Box>
      <Box>
        <Outlet />
      </Box>
    </Box>
  );
}

export default MainBackhead;

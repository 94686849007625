import React, { useEffect, useState } from "react";
import "./Model_Update_gems.scss";
import "../../App.scss";
import {
  Box,
  Button,
  ButtonGroup,
  Tab,
  Tabs,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { TabPanel } from "../../components/Login/Tabelpanel";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";
import ModalComponent from "../../components/ModalComponent/ModalComponent";

function Model_Update_gems_balance(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [values, setValues] = useState(0);
  const [alignment, setAlignment] = useState(true);
  const [wallet, setWallet] = useState();
  const [updateGems, setUpdateGems] = useState(null);
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const [purchasedGemsHandle, setPurchasedGemsHandle] = useState();

  useEffect(() => {
    const q1 = query(
      doc(
        collection(db, process.env.REACT_APP_WALLET_COLLECTION_NAME),
        `${location.state.val[0].idUser}`
      )
    );
    getDoc(q1).then((results) => {
      setWallet(results.data());
    });
  }, []);

  const handleAlignment = () => {
    setAlignment(!alignment);
  };
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const handleChange = (event, newValue) => {
    setValues(newValue);
  };

  const handleUpdateBalance = () => {
    if (updateGems !== null && updateGems) {
      console.log("updateGems", updateGems);
      let userUpadatedWallet = {
        ...wallet,
        balance: alignment
          ? parseInt(wallet.balance) + parseInt(updateGems)
          : parseInt(wallet.balance) - parseInt(updateGems),
      };
      updateDoc(
        doc(
          collection(db, process.env.REACT_APP_WALLET_COLLECTION_NAME),
          `${location.state.val[0].idUser}`
        ),
        userUpadatedWallet
      ).then(() => {
        setText(
          `Current Balance is ${
            alignment
              ? parseInt(wallet.balance) + parseInt(updateGems)
              : parseInt(wallet.balance) - parseInt(updateGems)
          }`
        );
        setOpen(true);
      });
    }
  };

  const handleUpdatePurchasedGems = () => {
    if (purchasedGemsHandle !== null && purchasedGemsHandle) {
      console.log("purchasedGemsHandle", purchasedGemsHandle);
      let userUpadatedWallet = {
        ...wallet,
        totalPurchasedGems: alignment
          ? parseInt(wallet.totalPurchasedGems) + parseInt(purchasedGemsHandle)
          : parseInt(wallet.totalPurchasedGems) - parseInt(purchasedGemsHandle),
      };
      updateDoc(
        doc(
          collection(db, process.env.REACT_APP_WALLET_COLLECTION_NAME),
          `${location.state.val[0].idUser}`
        ),
        userUpadatedWallet
      ).then(() => {
        setText(
          `Purchase Gems is ${
            alignment
              ? parseInt(wallet.totalPurchasedGems) +
                parseInt(purchasedGemsHandle)
              : parseInt(wallet.totalPurchasedGems) -
                parseInt(purchasedGemsHandle)
          }`
        );
        setOpen(true);
      });
    }
  };

  const handleClose = () => {
    navigate(-1);
    setOpen(false);
  };

  return (
    <>
      {wallet && (
        <Box className="m_ug_b">
          <Box className="m_ug_b_head">
            <Box className="menubtn">
              <ArrowBackIcon
                style={{ color: "white", marginTop: "0px" }}
                onClick={() => (props?.close ? props.close() : navigate(-1))}
              />
            </Box>
            <Typography
              variant="body1"
              className="typography-loginpage m_f text-shadow-typography"
            >
              {process.env.REACT_APP_UPDATE_BALANCE_TEXT}
            </Typography>
          </Box>
          <Box className="line" />
          <Box className="m_ug_tab">
            <Tabs
              value={values}
              onChange={handleChange}
              className="tab-menu "
              aria-label="basic tabs example"
            >
              <Tab
                className="typography-loginpage"
                label="Gif Gems"
                {...a11yProps(0)}
              />
              <Tab
                className="typography-loginpage"
                label="Update Purchased Gems"
                {...a11yProps(1)}
              />
            </Tabs>
            <Box className="tabpanels">
              <TabPanel value={values} index={0} className="a">
                <Typography
                  variant="body2"
                  className="typography-loginpage m_ug_yellow"
                  style={{ fontSize: "20px", textAlign: "center" }}
                >
                  {process.env.REACT_APP_UPDATE_GEMS_TEXT}
                </Typography>
                <Typography
                  variant="body2"
                  className="typography-loginpage m_f"
                  style={{
                    margin: "20px 0px",
                    fontSize: "20px",
                    textAlign: "center",
                  }}
                >
                  Current Balance Of {wallet.name} is {wallet.balance}
                </Typography>
                <Box className="b_update">
                  <ToggleButtonGroup
                    value={alignment}
                    exclusive
                    onChange={() => handleAlignment()}
                    aria-label="text alignment"
                    className="grp_bt"
                  >
                    <ToggleButton
                      value="true"
                      aria-label="left aligned"
                      className={alignment ? "yellow" : "not_yellow"}
                    >
                      +
                    </ToggleButton>
                    <ToggleButton
                      value="false"
                      aria-label="centered"
                      className={alignment ? "not_yellow" : "yellow"}
                    >
                      -{" "}
                    </ToggleButton>
                  </ToggleButtonGroup>
                  <TextField
                    type="number"
                    placeholder="Enter Description"
                    variant="outlined"
                    focused={false}
                    id="myprofile-form-field"
                    fullWidth
                    value={updateGems ? updateGems : null}
                    onChange={(e) => setUpdateGems(e.target.value)}
                    size="small"
                  />
                </Box>
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "30px",
                  }}
                >
                  <Button
                    variant="contained"
                    className="button_btn up_b"
                    onClick={() => handleUpdateBalance()}
                  >
                    {process.env.REACT_APP_UPDATE_GEMS_TEXT1}
                  </Button>
                </Box>
              </TabPanel>
              <TabPanel value={values} index={1} className="a">
                <Typography
                  variant="body2"
                  className="typography-loginpage m_ug_yellow"
                >
                  {process.env.REACT_APP_UPDATE_GEMS_NOTICE_TEXT}
                </Typography>
                <Typography
                  variant="body2"
                  className="typography-loginpage m_f"
                  style={{ marginTop: "20px", fontSize: "20px" }}
                >
                  Current Balance Of {wallet.name} is {wallet.balance}
                </Typography>
                <Typography
                  variant="body2"
                  className="typography-loginpage m_f"
                  style={{ margin: "20px 0px", fontSize: "20px" }}
                >
                  Total Purchased Gems Of {wallet.name} is{" "}
                  {wallet.totalPurchasedGems}
                </Typography>
                <Box className="b_update">
                  <ToggleButtonGroup
                    value={alignment}
                    exclusive
                    onChange={handleAlignment}
                    aria-label="text alignment"
                    className="grp_bt"
                    style={{ justifyContent: "space-between" }}
                  >
                    <ToggleButton
                      value="true"
                      aria-label="left aligned"
                      className={alignment ? "yellow" : "not_yellow"}
                      style={{ border: "none" }}
                    >
                      +
                    </ToggleButton>
                    <ToggleButton
                      value="false"
                      aria-label="centered"
                      className={alignment ? "not_yellow" : "yellow"}
                      style={{ border: "none" }}
                    >
                      -{" "}
                    </ToggleButton>
                  </ToggleButtonGroup>
                  <TextField
                    type="number"
                    placeholder="Enter Number of Gems"
                    variant="outlined"
                    focused={false}
                    className="text-shadow-typography"
                    id="myprofile-form-field"
                    fullWidth
                    size="small"
                    value={purchasedGemsHandle}
                    onChange={(e) => setPurchasedGemsHandle(e.target.value)}
                  />
                </Box>
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    marginTop: "30px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    className="button_btn up_b"
                    onClick={() => handleUpdatePurchasedGems()}
                  >
                    {process.env.REACT_APP_UPDATE_GEMS_TEXT1}
                  </Button>
                </Box>
              </TabPanel>
            </Box>
          </Box>
        </Box>
      )}
      {open && (
        <ModalComponent
          open={open}
          close={() => handleClose()}
          text={text}
          title={process.env.REACT_APP_DIALOG_APP_NAME}
        />
      )}{" "}
    </>
  );
}

export default Model_Update_gems_balance;

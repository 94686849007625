import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  getDoc,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { db } from "../../firebaseConfig/firebaseConfig";
import "./PaymentSucess.scss";
import { Typography } from "@mui/material";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Spinners from "../../components/Spinner/Spinners";

const PaymentSucess = () => {
  const navigate = useNavigate();
  let timeOut;
  const [searchParams, setSearchParams] = useSearchParams();
  const [count, setCount] = useState(5);
  const [loading, setLoading] = useState(true);
  const [receivedObject, setReceivedObject] = useState([]);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE))
  );
  const [wallet, setWallet] = useState();

  let newData = searchParams.get("q");
  let data = JSON.parse(atob(newData));

  useEffect(() => {
    compareData();
    const q2 = doc(
      db,
      process.env.REACT_APP_WALLET_COLLECTION_NAME,
      user.idUser
    );
    getDoc(q2).then((response) => {
      //  setSelectedUserWallet(response.data());
      const walletData = response?.data();
      setWallet(walletData);
      // console.log(response.data(), "RESPONSE");
    });
  }, []);

  useEffect(() => {
    if (!loading && count > 0) {
      const interval = setInterval(() => {
        setCount(count - 1);
      }, 1000);

      return () => clearInterval(interval);
    } else if (count == 0) {
      window.open("https://darkmattertechnologic.com/payment/");
    }
  }, [count, loading]);

  const compareData = () => {
    let arr = [];
    let q1 = query(
      collection(db, process.env.REACT_APP_RECHARGE_GEMS_ADD_COLLECTION_NAME),
      where("paymentToken", "==", parseInt(data.token)),
      where("prize", "==", parseInt(data.amount)),
      where("status", "==", process.env.REACT_APP_RECHARGE_GEMS_STATUS_START),
      where("gems", "==", data.gems),
      where("uid", "==", user.idUser)
    );
    getDocs(q1).then((result) => {
      result.forEach((doc) => {
        arr.push({ ...doc.data(), document_id: doc.id });
      });
      if (arr.length > 0) {
        let updatedWallet = {
          ...wallet,
          balance: parseInt(wallet?.balance) + parseInt(data.gems),
          totalPurchasedGems:
            parseInt(wallet?.totalPurchasedGems) + parseInt(data.gems),
          lastRechargeDate: serverTimestamp(),
        };
        updateDoc(
          doc(db, process.env.REACT_APP_WALLET_COLLECTION_NAME, user.idUser),
          { ...updatedWallet }
        );
        let rechargeGemsTable = {
          amount: parseInt(data.amount),
          email: user.email,
          image_url: user.urlAvatar,
          mobile: user.mobile,
          noOfGems: data.gems,
          platform: process.env.REACT_APP_PLATFORM,
          rechargeDate: serverTimestamp(),
          transcationId: data.transactionId,
          uid: user.idUser,
          user_name: user.name,
        };
        let userTransactionnsTable = {
          dm_message_gif: "",
          dm_message_image: "",
          dm_message_text: "",
          live_session_purchase_gif_name: "",
          live_session_purchase_comment_message: "",

          live_session_ref_id: "",
          platform: process.env.REACT_APP_PLATFORM,
          purchased_media_description: "",
          purchased_media_title: "",
          purchased_media_url: "",
          purchased_product_description: "",
          purchased_product_title: "",
          recharge_gems_transaction_amount: `${parseInt(data.amount)}`,
          recharge_gems_transaction_id: data.transactionId,
          transaction_gems: data.gems,
          transaction_gems_string: `+${data.gems}`,
          transaction_ref_id: "",
          transaction_time: serverTimestamp(),
          transaction_type_id:
            process.env.REACT_APP_RECHARGEGEMS_TRNSACTION_TYPE_ID,
          transaction_type_name:
            process.env.REACT_APP_RECHARGEGEMS_TRNSACTION_TYPE_NAME,
          updated_balance_after_transaction: `${updatedWallet.balance}`,
          user_email: user.email,
          user_id: user.idUser,
          user_name: user.name,
          version: process.env.REACT_APP_VERSION,
        };
        console.log("rechargeGemsTable", rechargeGemsTable);
        addDoc(
          collection(db, process.env.REACT_APP_REACHGEMS_COLLECTION),
          rechargeGemsTable
        ).then((result) => {
          userTransactionnsTable.transaction_ref_id = result.id;
          console.log(userTransactionnsTable, "asd");
          addDoc(
            collection(db, process.env.REACT_APP_USERTRANSACTIONS_COLLECTION),
            userTransactionnsTable
          );
          setLoading(false);
        });
        arr.map(async (ele) => {
          await deleteDoc(
            doc(
              db,
              process.env.REACT_APP_RECHARGE_GEMS_ADD_COLLECTION_NAME,
              ele.document_id
            )
          );
          setLoading(false);
        });
      } else if (arr.length == 0) {
        navigate(`/mainback/failed-recharge?q=${newData}`);
      }
    });
  };
  return (
    <div>
      {loading ? <Spinners /> : null}
      {!loading && (
        <div className="first">
          <div className="second">
            <div className="success2">
              <CheckRoundedIcon sx={{ fontSize: "10em", color: "white" }} />
            </div>
          </div>

          <Typography
            variant="h5"
            className="text-shadow-typography"
            sx={{ mt: 3, color: "white" }}
            gutterBottom
          >
            Payment Successful
          </Typography>
          <Typography
            variant="h6"
            sx={{ mt: 3, color: "white" }}
            gutterBottom
            className="text-shadow-typography"
          >
            Please wait for a second,
          </Typography>
          <Typography
            variant="h6"
            className="text-shadow-typography"
            sx={{ mt: 1, color: "white" }}
            gutterBottom
          >
            we will redirect you on main page
          </Typography>
          <Typography className="text-shadow-typography">
            Redirecting to home
          </Typography>
          <Typography>{count}</Typography>
        </div>
      )}
    </div>
  );
};

export default PaymentSucess;

import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "../../component/UploadPhoto/Uploadphoto.scss";
import jems from "../../component/Images/jems.png";
import ModalComponent from "../../components/ModalComponent/ModalComponent";
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";
import axios from "axios";
import Spinners from "../../components/Spinner/Spinners";
import { useLocation } from "react-router-dom";

function UpdatePhoto() {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const [file, setFile] = useState(null);
  const [photoDescription, setPhotoDescription] = useState({
    photoUrl: "",
    title: "",
    desc: "",
    noOfGems: "",
  });
  const fileUpload = useRef();

  useEffect(() => {
    if (location?.state?.photoobject) {
      let photoobj = location?.state?.photoobject;
      console.log(photoobj);
      setPhotoDescription({
        photoUrl: photoobj.photoUrl,
        title: photoobj.title,
        desc: photoobj.desc,
        noOfGems: photoobj.noOfGems,
      });
    }
  }, []);

  const handleUpdate = () => {
    if (photoDescription.title.trim() == "") {
      setText(process.env.REACT_APP_MODAL_TEXT_ENTER_TITLE);
      setOpen(true);
    } else if (photoDescription.desc.trim() == "") {
      setText(process.env.REACT_APP_MODAL_TEXT_ENTER_DISCRIPTION);
      setOpen(true);
    } else if (photoDescription.noOfGems == null) {
      setText(process.env.REACT_APP_MODAL_TEXT_ENTER_GEMS);
      setOpen(true);
    } else {
      setIsLoading(true);
      let photo = { ...location?.state?.photoobject };
      delete photo.commentCounter;
      delete photo.id;
      delete photo.likedCounter;
      delete photo.myLiked;

      updateDoc(
        doc(
          db,
          process.env.REACT_APP_PHOTO_UPLOAD_COLLECTION,
          location?.state?.photoobject?.id
        ),
        {
          ...photo,
          title: photoDescription.title,
          desc: photoDescription.desc,
          noOfGems: parseInt(photoDescription.noOfGems),
          createdAt: new Date(
            location?.state?.photoobject.createdAt.seconds * 1000 +
              location?.state?.photoobject.createdAt.nanoseconds / 1000000
          ),
        }
      ).then(() => {
        setIsLoading(false);
        setOpen(true);
        setText("Photo Details are updated successfully!");
        setPhotoDescription({
          ...location?.state?.photoobject,
          title: photoDescription.title,
          desc: photoDescription.desc,
          noOfGems: photoDescription.noOfGems,
        });
      });
    }
  };

  const handlePromote = () => {
    if (photoDescription.title.trim() == "") {
      setText(process.env.REACT_APP_MODAL_TEXT_ENTER_TITLE);
      setOpen(true);
    } else if (photoDescription.desc.trim() == "") {
      setText(process.env.REACT_APP_MODAL_TEXT_ENTER_DISCRIPTION);
      setOpen(true);
    } else if (photoDescription.noOfGems == null) {
      setText(process.env.REACT_APP_MODAL_TEXT_ENTER_GEMS);
      setOpen(true);
    } else {
      let photoDetails = location?.state?.photoobject;
      setIsLoading(true);
      addDoc(
        collection(db, process.env.REACT_APP_PROMOTECONTENT_COLLECTION_NAME),
        {
          createdAt: serverTimestamp(),
          itemId: photoDetails?.id,
          itemPhotoUrl: photoDetails.photoUrl,
          itemTitle: photoDescription.title,
          noOfComments: `${photoDetails?.commentCounter}`,
          desc: photoDescription.desc,
          noOfGems: parseInt(photoDescription.noOfGems),
          noOfLikes: `${photoDetails?.likedCounter}`,
          tag: "photo",
          vdoUrl: "none",
        }
      ).then(() => {
        setIsLoading(false);
        setOpen(true);
        setText("Content Promoted successfully!");
        setPhotoDescription({
          ...location?.state?.photoobject,
          title: photoDescription.title,
          desc: photoDescription.desc,
          noOfGems: photoDescription.noOfGems,
        });
      });
    }
  };

  const close = () => {
    setOpen(false);
  };

  return (
    <>
      {isLoading ? <Spinners /> : null}
      <Box className="upmain">
        <Box>
          <Typography variant="h6" id="updiv">
            Update Photo Details
          </Typography>
        </Box>

        <Box id="line" />
        <Box className="formdiv">
          <Typography variant="h6" id="formheadig">
            {process.env.REACT_APP_UPLOAD_PHOTO_MEDIA_DETAILS}
          </Typography>
          <Box className="formimgdiv">
            {photoDescription.photoUrl == "" ? null : (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <img
                  alt=""
                  src={photoDescription.photoUrl}
                  style={{
                    height: "200px",
                    width: "auto",
                    borderRadius: "0px",
                  }}
                />
              </Box>
            )}

            {photoDescription.photoUrl == "" ? (
              <Typography variant="body1" id="formimgheadig">
                {process.env.REACT_APP_NO_PHOTO_SELECTED_TEXT}
              </Typography>
            ) : null}
          </Box>
          <Box>
            <Grid container className="box" columnSpacing={{ xs: 1 }}>
              <Grid item xs={4}>
                <Typography variant="body1" id="formtext">
                  {process.env.REACT_APP_TITLE_TEXT}
                </Typography>
              </Grid>
              <Grid item xs={8} className="upinput">
                <TextField
                  type="text"
                  placeholder={
                    process.env.REACT_APP_PLACEHOLDER_TEXT_ENTER_TITLE
                  }
                  variant="outlined"
                  focused={false}
                  id="textfild"
                  value={photoDescription.title}
                  onChange={(e) =>
                    setPhotoDescription({
                      ...photoDescription,
                      title: e.target.value,
                    })
                  }
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container className="box" columnSpacing={{ xs: 1 }}>
              <Grid item xs={4}>
                <Typography variant="body2" id="formtext">
                  {process.env.REACT_APP_DISCRIPTION_TEXT}
                </Typography>
              </Grid>
              <Grid item xs={8} className="upinput2">
                <TextField
                  type="text"
                  placeholder={
                    process.env.REACT_APP_PLACEHOLDER_TEXT_ENTER_DISCRIPTION
                  }
                  variant="outlined"
                  focused={false}
                  id="textfild"
                  value={photoDescription.desc}
                  onChange={(e) =>
                    setPhotoDescription({
                      ...photoDescription,
                      desc: e.target.value,
                    })
                  }
                  fullWidth
                  multiline
                  rows={3}
                />
              </Grid>
            </Grid>
            <Grid container className="box" columnSpacing={{ xs: 1 }}>
              <Grid item xs={4}>
                <img alt="" src={jems} className="formimg" />
              </Grid>
              <Grid item xs={8} className="upinput">
                <TextField
                  type="text"
                  placeholder={
                    process.env.REACT_APP_PLACEHOLDER_TEXT_ENTER_GEMS
                  }
                  variant="outlined"
                  value={photoDescription.noOfGems}
                  onChange={(e) =>
                    setPhotoDescription({
                      ...photoDescription,
                      noOfGems: e.target.value,
                    })
                  }
                  focused={false}
                  id="textfild"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid
              container
              className="box"
              columnSpacing={{ xs: 1 }}
              style={{ marginLeft: 0, paddingInline: 0 }}
            >
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  id="publishbtn"
                  onClick={() => handleUpdate()}
                  style={{ width: "100%" }}
                >
                  Update & Publish
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  style={{ width: "calc(100% - 15px)" }}
                  onClick={() => handlePromote()}
                  variant="contained"
                  id="publishbtn"
                >
                  Promote Photo
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <ModalComponent
        close={close}
        open={open}
        text={text}
        title={process.env.REACT_APP_DIALOG_APP_NAME}
      />
    </>
  );
}

export default UpdatePhoto;

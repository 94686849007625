import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import not_live from "../../assests/livePlaceholder.jpg";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import "./UserLive.scss";
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";
import LiveStrimingPage from "./LiveStrimingPage";
import ModalComponent from "../../components/ModalComponent/ModalComponent";
import { useNavigate } from "react-router-dom";

function UserLive() {
  const [muted, setMuted] = useState(false);
  const [liveData, setLiveData] = useState({});
  const [open, setOpen] = useState(false);
  const [text, setText] = useState({ title: "", text: "" });
  const navigate = useNavigate();
  const [askedLeaveScreenPopup, setAskedLeaveScreenPopup] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    let q4 = query(
      collection(db, process.env.REACT_APP_LIVE_STREMING_COLLECTION_NAME),
      orderBy("createdOn", "desc"),
      limit(1)
    );
    onSnapshot(q4, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        setLiveData({ ...doc.data(), liveStrimingDocId: doc.id });
      });
    });
  }, []);

  return (
    <Box>
      {Object.keys(liveData).length > 0 &&
      liveData.liveStatus &&
      liveData.state == "new" ? (
        <>
          <LiveStrimingPage liveData={liveData} />
        </>
      ) : (
        <>
          {/* <video
            src={not_live}
            autoplay="true"
            loop
            muted={muted}
            controls={false}
            playsInline={true}
            style={{
              maxWidth: "100vw",
              position: "fixed",
              left: "0px",
              top: "62.33px",
              width: "100vw",
            }}
          ></video> */}
          <img
            src={not_live}
            style={{
              maxWidth: "100vw",
              position: "fixed",
              left: "0px",
              top: "62.33px",
              width: "100%",
              height: "86%",
              objectFit: "fill",
            }}
          />
          {/* <Box className="volumebox">
            <IconButton id="btnMute" onClick={() => setMuted(!muted)}>
              {!muted ? (
                <VolumeUpIcon
                  color="var(--white)"
                  style={{ color: "var(--white)" }}
                />
              ) : (
                <VolumeOffIcon
                  color="var(--white)"
                  style={{ color: "var(--white)" }}
                />
              )}
            </IconButton>
          </Box> */}
          <Box className="livebtnbox">
            <Button
              variant="contained"
              className="livebtn"
              onClick={() => {
                Object.values(liveData).includes("new")
                  ? setText({
                      title: liveData.title,
                      text: `This live will be ${liveData.noOfGems}`,
                    })
                  : setText({
                      title: process.env.REACT_APP_DIALOG_APP_NAME,
                      text: "Check Again For Live Notification",
                    });
                setOpen(true);
              }}
            >
              {process.env.REACT_APP_LIVE_TIMINGS_BUTTON}
            </Button>
          </Box>
        </>
      )}

      {open && (
        <ModalComponent
          open={open}
          close={() => handleClose()}
          text={text.text}
          title={text.title}
        />
      )}
    </Box>
  );
}

export default UserLive;

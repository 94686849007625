import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import VideocamIcon from "@mui/icons-material/Videocam";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../../../firebaseConfig/firebaseConfig";
import { useNavigate } from "react-router-dom";
import Spinners from "../../../components/Spinner/Spinners";
import Spinner2 from "../../../components/Spinner/Spinner2";

function Completed() {
  const navigate = useNavigate();
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE))
  );
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getRejectedRequest();
  }, []);

  const getRejectedRequest = async () => {
    let completedRequest = [];
    const q1 = query(
      collection(
        db,
        process.env.REACT_APP_COMPLETED_SHOUTOUT_REQUEST_COLLECTION
      ),
      where("uid", "==", user.idUser),
      orderBy("completedOn", "desc")
    );
    let data = await getDocs(q1);
    data.forEach((doc) => completedRequest.push(doc.data()));
    setData(completedRequest);
    setLoading(false);
  };

  return (
    <>
      {loading ? <Spinner2 /> : null}

      {!loading && data.length == 0 && (
        <div
          style={{
            position: "fixed",
            top: "45%",
            left: 0,
            width: "100vw",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography>No Data</Typography>
        </div>
      )}
      {data.length > 0 &&
        data.map((val) => {
          let dateTimeString;
          if (val.requestedOn) {
            dateTimeString = `${val.requestedOn
              .toDate()
              .toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })} ${new Date(val.requestedOn.toDate()).toLocaleDateString(
              "en-US",
              { year: "numeric", month: "long", day: "numeric" }
            )}`;
          }
          let dateTimeString2;
          if (val.completedOn) {
            dateTimeString2 = `${val.completedOn
              .toDate()
              .toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })} ${new Date(val.completedOn.toDate()).toLocaleDateString(
              "en-US",
              { year: "numeric", month: "long", day: "numeric" }
            )}`;
          }
          return (
            <Box className="complet_text_box">
              <Typography variant="body2" className="t">
                {val.requestedBy}
              </Typography>
              <Typography variant="body2" className="t">
                {process.env.REACT_APP_WALLET_EMAIL_TEXT} &nbsp;{user.email}
              </Typography>
              <Typography variant="body2" className="t">
                {process.env.REACT_APP_REQUESTED_AT_TEXT}&nbsp;{dateTimeString}
              </Typography>
              <Typography variant="body2" className="t">
                {process.env.REACT_APP_COMPLETED_AT_TEXT} &nbsp;
                {dateTimeString2}
              </Typography>
              <Typography variant="body2" className="t">
                {process.env.REACT_APP_MESSAGE_TEXT}:&nbsp;{val.message}
              </Typography>
              <Typography variant="body2" className="t">
                {process.env.REACT_APP_VIDEO_FROM_MODAL_TEXT}:
              </Typography>
              <Box className="video_icon_box">
                <VideocamIcon
                  onClick={() =>
                    navigate("/videos", { state: { val: val.vdoUrl } })
                  }
                />
              </Box>
            </Box>
          );
        })}
    </>
  );
}

export default Completed;

import { Box, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./Photopage.scss";
import FavoriteIcon from "@mui/icons-material/Favorite";
import MessageIcon from "@mui/icons-material/Message";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { useNavigate } from "react-router";
import ModelProfilePicture from "../../assests/ic_logo.jpg";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  limit,
  onSnapshot,
  where,
  orderBy,
  query,
  serverTimestamp,
  startAfter,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinners from "../../components/Spinner/Spinners";
import Opimg from "../Opimg/Opimg";
import Comment from "../commentpage/Commentpage";
import { getAuth } from "firebase/auth";
import ModalforPurchase from "./ModalforPurchase";

function Photopage() {
  const navigate = useNavigate();
  const auth = getAuth();
  const [loading, setLoading] = useState(true);
  const [photo, setPhoto] = useState({});
  const [lastDoc, setLastDoc] = useState(null);
  const [openPhoto, setOpenPhoto] = useState({ open: false, val: "" });
  const [openComment, setOpenComment] = useState({ open: false, val: "" });
  const [myPurchedPhotos, setMyPurchedPhotos] = useState(
    JSON.parse(
      localStorage.getItem(process.env.REACT_APP_PURCHASEDPHOTO_LOCALSTORAGE)
    )
  );
  const [wallet, setWallet] = useState();
  const [open, setOpen] = useState({ open: false, val: "" });
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE))
  );
  const [testUser, setTestUser] = useState(
    JSON.parse(localStorage.getItem("testUser") ?? false)
  );
  const userID = JSON.parse(
    localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE)
  ).idUser;

  useEffect(() => {
    const q = query(
      collection(db, process.env.REACT_APP_WALLET_COLLECTION_NAME),
      where(
        "uid",
        "==",
        JSON.parse(
          localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE)
        ).idUser
      )
    );
    onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        setWallet(doc.data());
        localStorage.setItem(
          process.env.REACT_APP_WALLET_LOCALSTORAGE,
          JSON.stringify(doc.data())
        );
      });
    });
  }, []);
  const handleClickOpen = (ele) => {
    if (wallet?.balance < ele.noOfGems) {
      setOpen({ open: true, val: ele });
    } else {
      setOpen({ open: true, val: ele });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const getLikedCounters = (photos) => {
    const promises = Object.keys(photos).map((id) => {
      const q1 = query(
        collection(db, process.env.REACT_APP_PHOTOS_COLLECTION, id, "liked")
      );
      return getDocs(q1);
    });
    const promisesComments = Object.keys(photos).map((id) => {
      const q2 = query(
        collection(
          db,
          process.env.REACT_APP_PHOTOS_COMMENTS_COLLECTION,
          id,
          "comments"
        )
      );
      return getDocs(q2);
    });
    Promise.all([...promises, ...promisesComments]).then((values) => {
      values.map((data) => {
        if (!data.empty) {
          const path = data.query.path.split("/");
          const docID = path[1];
          if (path[2] == "comments") {
            photos[docID].commentCounter = data.docs.length;
          } else {
            const myLiked =
              data.docs.filter((x) => x.data().userId == userID).length > 0;
            photos[docID].likedCounter = data.docs.length;
            photos[docID].myLiked = myLiked;
          }
        }
      });
      setPhoto({ ...photo, ...photos });
    });
  };
  const getPhotosData = (lastDoc = null) => {
    let q4;
    if (lastDoc) {
      q4 = query(
        collection(db, process.env.REACT_APP_PHOTOS_COLLECTION),
        orderBy("createdAt", "desc"),
        startAfter(lastDoc),
        limit(15)
      );
    } else {
      q4 = query(
        collection(db, process.env.REACT_APP_PHOTOS_COLLECTION),
        orderBy("createdAt", "desc"),
        limit(15)
      );
    }

    getDocs(q4).then((result) => {
      let photos = {};
      setLastDoc(result.docs[result.docs.length - 1]);
      result.forEach((doc) => {
        photos = {
          ...photos,
          [doc.id]: {
            ...doc.data(),
            id: doc.id,
            likedCounter: 0,
            commentCounter: 0,
            myLiked: false,
          },
        };
      });
      setPhoto({ ...photo, ...photos });
      getLikedCounters(photos);
      setLoading(false);
    });
  };
  useEffect(() => {
    getPhotosData();
    window.scrollTo({ top: 0, behavior: "smooth" });
    // setLoading(false);
  }, []);

  const handleLike = (ele) => {
    let obj = {
      userId: userID,
    };
    addDoc(
      collection(
        db,
        process.env.REACT_APP_PHOTOS_COLLECTION,
        `${ele.id}`,
        "liked"
      ),
      obj
    ).then(() => {});
    setPhoto({
      ...photo,
      [ele.id]: {
        ...photo[`${ele.id}`],
        likedCounter: photo[`${ele.id}`].likedCounter + 1,
        myLiked: true,
      },
    });
  };

  const handleComment = (ele) => {
    setPhoto({
      ...photo,
      [`${ele.id}`]: {
        ...photo[`${ele.id}`],
        commentCounter: photo[`${ele.id}`].commentCounter + 1,
      },
    });
  };

  const closePhoto = () => {
    setOpenPhoto(false);
  };
  const closeComment = () => {
    setOpenComment(false);
  };

  const handlePurchase = (val) => {
    const q2 = query(
      collection(db, process.env.REACT_APP_USERS_COLLECTION_NAME),
      where("idUser", "==", wallet?.uid)
    );

    getDocs(q2).then((result) => {
      console.log(result, "REsult");
      result.forEach((users) => {
        if (users.data()?.blocked) {
          console.log("TRUE");
          // navigate("/login");
          navigate("/login");
          localStorage.clear();
        } else {
          let photoPurchase = {
            boughtOn: serverTimestamp(),
            photoUrl: val.photoUrl,
            photocreatedAt: val.createdAt,
            photodesc: val.desc,
            photoid: val.id,
            photomonitizeState:
              process.env.REACT_APP_UPLOAD_PHOTO_MONITIZE_STATE,
            photonoofgems: val.noOfGems,
            phototitle: val.title,
            platform: process.env.REACT_APP_PLATFORM,
            uid: auth.currentUser.uid,
            version: process.env.REACT_APP_VERSION,
          };
          let updatedWallet = {
            ...wallet,
            balance: wallet?.balance - val.noOfGems,
          };
          setWallet(updatedWallet);
          localStorage.setItem(
            process.env.REACT_APP_WALLET_LOCALSTORAGE,
            JSON.stringify(updatedWallet)
          );

          const querySnapshot = doc(
            db,
            process.env.REACT_APP_WALLET_COLLECTION_NAME,
            user.idUser
          );
          updateDoc(querySnapshot, {
            balance: updatedWallet.balance,
          }).then(() => {});
          setMyPurchedPhotos([...myPurchedPhotos, photoPurchase]);
          localStorage.setItem(
            process.env.REACT_APP_PURCHASEDPHOTO_LOCALSTORAGE,
            JSON.stringify([...myPurchedPhotos, photoPurchase])
          );
          addDoc(
            collection(
              db,
              process.env.REACT_APP_MYPURCHASEDPHOTOS_COLLECTION_NAME
            ),
            photoPurchase
          ).then((result) => {
            let photo_transaction_type_name =
              process.env.REACT_APP_PHOTO_TRANSACTION_TYPE_NAME;
            let photo_transaction_type_id =
              process.env.REACT_APP_PHOTO_TRANSACTION_TYPE_ID;
            let userTransactionnsTable = {
              dm_message_gif: "",
              dm_message_image: "",
              dm_message_text: "",
              live_session_purchase_gif_name: "",
              live_session_purchase_comment_message: "",

              live_session_ref_id: "",
              platform: process.env.REACT_APP_PLATFORM,
              purchased_media_description: photoPurchase.photodesc,
              purchased_media_title: photoPurchase.phototitle,
              purchased_media_url: photoPurchase.photoUrl,
              purchased_product_description: "",
              purchased_product_title: "",
              recharge_gems_transaction_amount: "",
              recharge_gems_transaction_id: "",
              transaction_gems: photoPurchase.photonoofgems,
              transaction_gems_string: `-${photoPurchase.photonoofgems}`,
              transaction_ref_id: photoPurchase.photoid,
              transaction_time: serverTimestamp(),
              transaction_type_id: photo_transaction_type_id,
              transaction_type_name: photo_transaction_type_name,
              updated_balance_after_transaction: `${updatedWallet.balance}`,
              user_email: user.email,
              user_id: user.idUser,
              user_name: user.name,
              version: process.env.REACT_APP_VERSION,
            };
            if (!testUser) {
              addDoc(
                collection(
                  db,
                  process.env.REACT_APP_USERTRANSACTIONS_COLLECTION
                ),
                userTransactionnsTable
              ).then(() => {});
            }
          });

          setOpen({ open: false, val: "" });
        }
      });
    });
  };
  return (
    <>
      <Box className="general">
        <Box className="homecss">
          <Box className="homepage">
            {!loading && Object.keys(photo).length == 0 ? (
              <Typography
                style={{ top: "50%", left: "40%", position: "fixed" }}
              >
                No Data
              </Typography>
            ) : (
              <>
                {loading && <Spinners />}
                <Box>
                  <Box className="headingdiv">
                    <Typography variant="h6" id="heading">
                      {process.env.REACT_APP_PHOTO_PAGE_TITLE}
                    </Typography>
                  </Box>
                  <Box className="photomain">
                    <InfiniteScroll
                      dataLength={Object.keys(photo).length}
                      next={() => {
                        getPhotosData(lastDoc);
                      }}
                      hasMore={true}
                    >
                      {Object.values(photo).map((ele) => {
                        let isFree;
                        if (testUser) {
                          isFree = true;
                        } else {
                          isFree = ele.noOfGems == 0;
                        }
                        let isPurchased;
                        if (testUser) {
                          isPurchased = true;
                        } else {
                          isPurchased =
                            myPurchedPhotos.filter(
                              (pho) => pho.photoid == ele.id
                            ).length > 0;
                        }
                        const lockedImage = !isPurchased && !isFree;
                        const lockedStrip = !isFree
                          ? `${
                              !isPurchased
                                ? process.env.REACT_APP_LOCKED_STRIP_TEXT
                                : process.env.REACT_APP_UNLOCKED_STRIP_TEXT
                            } Image`
                          : false;
                        return (
                          <Box>
                            <Grid container className="photodiv">
                              <Grid item className="photodiv_imgdiv">
                                <div className="profilepic">
                                  <img
                                    alt=""
                                    src={ModelProfilePicture}
                                    className="photoimg"
                                  />
                                </div>
                              </Grid>
                              <Grid item className="profiletext">
                                <Typography
                                  variant="body1"
                                  className="text-align-start"
                                  id="photousername"
                                >
                                  {process.env.REACT_APP_MODAL_NAME}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  className="text-align-start"
                                  id="photocaption"
                                >
                                  {ele.desc}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Box>
                              <Box className="postimgdiv">
                                <Box className="postdiv">
                                  <img
                                    alt=""
                                    src={ele.photoUrl}
                                    className="postimg"
                                    id={lockedImage ? "lockimg" : ""}
                                    onClick={() =>
                                      isPurchased || isFree
                                        ? setOpenPhoto({ open: true, val: ele })
                                        : handleClickOpen(ele)
                                    }
                                  />
                                </Box>
                                {!isPurchased && !isFree && (
                                  <Box
                                    className="lockcontant"
                                    onClick={() => handleClickOpen(ele)}
                                  >
                                    <LockIcon
                                      style={{
                                        fontSize: "50px",
                                        marginRight: "2px",
                                      }}
                                    />
                                    <Typography variant="body1">
                                      Unlock Photo at {ele.noOfGems} GEMS
                                    </Typography>
                                  </Box>
                                )}
                                {!isFree ? (
                                  <Box className="imlock">
                                    {!isPurchased ? (
                                      <LockIcon
                                        style={{
                                          fontSize: "18px",
                                          marginRight: "2px",
                                        }}
                                      />
                                    ) : (
                                      <LockOpenIcon
                                        style={{
                                          fontSize: "18px",
                                          marginRight: "2px",
                                        }}
                                      />
                                    )}
                                    <Typography variant="body2">
                                      {!isPurchased
                                        ? "Locked Image"
                                        : "UnLocked Image"}
                                    </Typography>
                                  </Box>
                                ) : null}
                                {isFree || isPurchased ? (
                                  <Grid
                                    container
                                    className="photolikediv"
                                    style={{ marginTop: "10px" }}
                                  >
                                    <Grid
                                      item
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <IconButton>
                                        {ele.myLiked ? (
                                          <FavoriteIcon
                                            id="btnlikecm"
                                            style={{ color: "var(--red)" }}
                                          />
                                        ) : (
                                          <FavoriteIcon
                                            id="btnlikecm"
                                            onClick={() => handleLike(ele)}
                                          />
                                        )}
                                      </IconButton>
                                      <Typography
                                        variant="body1"
                                        id="photousername"
                                      >
                                        {ele.likedCounter}
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <IconButton
                                        onClick={() =>
                                          setOpenComment({
                                            open: true,
                                            val: ele,
                                          })
                                        }
                                      >
                                        <MessageIcon id="btnlikecm" />
                                      </IconButton>
                                      <Typography
                                        variant="body1"
                                        id="photousername"
                                      >
                                        {ele.commentCounter}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                ) : null}
                              </Box>

                              <Box className="photoendline" />
                            </Box>
                            <Box></Box>
                          </Box>
                        );
                      })}
                    </InfiniteScroll>
                  </Box>

                  {open.open && (
                    <ModalforPurchase
                      open={open.open}
                      val={open}
                      wallet={wallet}
                      close={() => handleClose()}
                      handlePurchase={(ele) => handlePurchase(ele)}
                    />
                  )}
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
      {openPhoto.open ? (
        <Opimg
          open={openPhoto.open}
          close={() => closePhoto()}
          val={openPhoto.val}
          addCommentCount={(ele) => handleComment(ele)}
          likeHandling={(ele) => handleLike(ele)}
          from="photospage"
        />
      ) : null}
      {openComment.open ? (
        <Comment
          open={openComment.open}
          close={() => closeComment()}
          val={openComment.val}
          from="photospage"
          addCommentCount={(ele) => handleComment(ele)}
        />
      ) : null}{" "}
    </>
  );
}

export default Photopage;

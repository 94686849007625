import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import React from "react";
import jems from "../../../component/Images/jems.png";

const WalletAskModal = (props) => {
  return (
    <Dialog
      open={props.open}
      onClose={() => props.close()}
      className="photopopup"
      aria-labelledby="draggable-dialog-title"
    >
      <DialogContent id="context">
        <DialogContentText className="context-div">
          <Typography variant="h6" id="modeltext">
            {process.env.REACT_APP_WALLET_BALANCE_TEXT}:{props.wallet.balance}
          </Typography>
          <img alt="" src={jems} className="jemsimg" />
        </DialogContentText>
      </DialogContent>
      <DialogActions className="modelbtndiv">
        <Button variant="contained" onClick={() => props.submit()} id="popbtn">
          {process.env.REACT_APP_BUY_FOR_TEXT} &nbsp;
          {props.price}
          <img alt="" src={jems} className="jemsimg" />
        </Button>
        <Button variant="contained" onClick={() => props.close()} id="popbtn">
          {process.env.REACT_APP_MODAL_CANCEL_BUTTON_TEXT}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WalletAskModal;

import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import BackGround from "../../assests/videoCall.jpg";
import Logo from "../../assests/logo.png";
import "./Forgotpassword.scss";
import { useNavigate } from "react-router";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import Spinners from "../Spinner/Spinners";
import ModalComponent from "../ModalComponent/ModalComponent";

const ForgotPassWord = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");

  const auth = getAuth();

  const resetPassword = () => {
    if (email.trim() == "") {
      setText("Please enter Email Address");
      setOpen(true);
    } else if (
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email.trim()) ==
      false
    ) {
      setText(process.env.REACT_APP_INVALID_EMAIL_MODAL_TEXT);
      setOpen(true);
    } else {
      setLoading(true);
      return sendPasswordResetEmail(auth, email).then((a) => {
        setText(process.env.REACT_APP_SEND_PASSWORD_MODAL_TEXT);
        setOpen(true);
        setLoading(false);
      });
    }
  };

  const close = () => {
    setOpen(false);
  };

  return (
    <Box className="general">
      {loading ? <Spinners /> : null}
      <Box
        className="forgotpassword-container"
        style={{
          backgroundImage: `url(${BackGround})`,
        }}
      >
        <img src={Logo} />

        <TextField
          placeholder={process.env.REACT_APP_ENTER_REGISTERD_EMAIL_PLACEHOLDER}
          variant="outlined"
          className="textfield"
          focused={false}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => resetPassword()}
          className="forgotpassword-page-button typography-loginpage loginpage-button bgPink button-color"
        >
          {process.env.REACT_APP_SUBMIT_BUTTON}
        </Button>
        <Typography className="text-align-center">
          {process.env.REACT_APP_RECEIVE_LINK_FORGOT_PASSWORD_HEADING}
        </Typography>

        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/login")}
          className="forgotpassword-page-button typography-loginpage loginpage-button bgPink button-color"
        >
          {process.env.REACT_APP_BACK_TO_LOGIN_BUTTON}
        </Button>
      </Box>
      <ModalComponent
        close={close}
        open={open}
        text={text}
        title={process.env.REACT_APP_DIALOG_APP_NAME}
      />
    </Box>
  );
};

export default ForgotPassWord;

import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import LiveImage from "../../assests/livePlaceholder.jpg";
import { useNavigate } from "react-router-dom";
import AgoraRTC from "agora-rtc-sdk";
import ModalComponent from "../../components/ModalComponent/ModalComponent";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  getDocs,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";
import ModalforPurchase from "../Photopage/ModalforPurchase";

const LiveStrimingPage = (props) => {
  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const [liveData, setLiveData] = useState({});
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE))
  );
  const [joinedUser, setJoiedUser] = useState({});
  const [open, setOpen] = useState({ open: false, val: "" });
  const [wallet, setWallet] = useState();
  const [testUser, setTestUser] = useState(
    JSON.parse(localStorage.getItem("testUser") ?? false)
  );

  useEffect(() => {
    let q4 = query(
      collection(db, process.env.REACT_APP_LIVE_STREMING_COLLECTION_NAME),
      orderBy("createdOn", "desc"),
      limit(1)
    );
    onSnapshot(q4, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        setLiveData({ ...doc.data(), liveStrimingDocId: doc.id });
      });
    });

    const q = query(
      collection(db, process.env.REACT_APP_WALLET_COLLECTION_NAME),
      where(
        "uid",
        "==",
        JSON.parse(
          localStorage.getItem(process.env.REACT_APP_USERS_LOCALSTORAGE)
        ).idUser
      )
    );
    onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        setOpen({ open: false, val: "" });
        setWallet(doc.data());
        localStorage.setItem(
          process.env.REACT_APP_WALLET_LOCALSTORAGE,
          JSON.stringify(doc.data())
        );
      });
    });
  }, []);

  const handleClose = () => {
    setOpen(false);
  };
  var rtc = {
    client: null,
    joined: false,
    published: false,
    localStream: null,
    remoteStreams: [],
    params: {},
  };

  const getUserJoinInfo = () => {
    let q1 = query(
      collection(
        db,
        process.env.REACT_APP_LIVE_STREMING_COLLECTION_NAME,
        props.liveData?.liveStrimingDocId,
        process.env.REACT_APP_WHO_JOIN_LIVE_COLLECTION_NAME
      ),
      where("uid", "==", user.idUser),
      limit(1)
    );
    onSnapshot(q1, (querySnapshot) => {
      querySnapshot.forEach((result) => {
        setJoiedUser({ ...result.data(), userJoinId: result.id });
      });
    });
  };

  useEffect(() => {
    getUserJoinInfo();
  }, []);

  const handleprejoinqueries = () => {
    if (Object.keys(joinedUser).length > 0) {
      // joinChannel("audience");
      updateDoc(
        doc(
          db,
          process.env.REACT_APP_LIVE_STREMING_COLLECTION_NAME,
          props.liveData?.liveStrimingDocId,
          process.env.REACT_APP_WHO_JOIN_LIVE_COLLECTION_NAME,
          joinedUser.userJoinId
        ),
        { liveJoinStatus: "join" }
      );

      navigate("/livestrmingpage", {
        state: {
          role: "audience",
          strimingid: props.liveData?.liveStrimingDocId,
          accessToken: props.liveData?.accessToken,
          commentsFlag: props.liveData?.enableCommentsInLiveStreaming,
          gemsForUserComment: props.liveData?.gemForUserComment,
        },
      });
    } else {
      setOpen({ open: true, val: props.liveData });
    }
  };
  const handlePurchase = (val) => {
    if (wallet.balance >= val.noOfGems) {
      const q2 = query(
        collection(db, process.env.REACT_APP_USERS_COLLECTION_NAME),
        where("idUser", "==", wallet?.uid)
      );

      getDocs(q2).then((result) => {
        result.forEach((users) => {
          if (users.data()?.blocked) {
            // navigate("/login");
            navigate("/login");
            localStorage.clear();
          } else {
            if (Object.keys(joinedUser).length == 0) {
              let updatedWallet = {
                ...wallet,
                balance: wallet?.balance - props.liveData?.noOfGems,
              };
              setWallet(updatedWallet);
              localStorage.setItem(
                process.env.REACT_APP_WALLET_LOCALSTORAGE,
                JSON.stringify(updatedWallet)
              );

              const querySnapshot = doc(
                db,
                process.env.REACT_APP_WALLET_COLLECTION_NAME,
                user.idUser
              );
              updateDoc(querySnapshot, {
                balance: updatedWallet.balance,
              }).then(() => {});
              if (!testUser) {
                let userTransactionnsTable = {
                  dm_message_gif: "",
                  dm_message_image: "",
                  dm_message_text: "",
                  live_session_purchase_gif_name: "",
                  live_session_purchase_comment_message: "",
                  live_session_ref_id: props.liveData?.liveStrimingDocId,
                  platform: process.env.REACT_APP_PLATFORM,
                  purchased_media_description: "",
                  purchased_media_title: "",
                  purchased_media_url: "",
                  purchased_product_description: "",
                  purchased_product_title: "",
                  recharge_gems_transaction_amount: "",
                  recharge_gems_transaction_id: "",
                  transaction_gems: props.liveData?.noOfGems,
                  transaction_gems_string: `-${props.liveData?.noOfGems}`,
                  transaction_ref_id: props.liveData?.liveStrimingDocId,
                  transaction_time: serverTimestamp(),
                  transaction_type_id: "5",
                  transaction_type_name: "joined_live_streaming",
                  updated_balance_after_transaction: `${updatedWallet.balance}`,
                  user_email: user.email,
                  user_id: user.idUser,
                  user_name: user.name,
                  version: process.env.REACT_APP_VERSION,
                };
                addDoc(
                  collection(
                    db,
                    process.env.REACT_APP_USERTRANSACTIONS_COLLECTION
                  ),
                  userTransactionnsTable
                ).then(() => {});
              }
              let liveJoinUser = {
                brand: "web",
                deviceModel: "web",
                deviceOsVersion: "-",
                liveJoinStatus: "join",
                liveSessionId: props.liveData?.liveStrimingDocId,
                liveSessionJoinAt: serverTimestamp(),
                noOfGems: props.liveData?.noOfGems,
                platform: process.env.REACT_APP_PLATFORM,
                uid: user.idUser,
                userDp: `${process.env.REACT_APP_IMAGE_GET_CLOUDFAIR_URL1}6769b712-0f0e-48c3-0db2-db9ebd2c2900/public`,
                userEmail: user.email,
                userName: user.name,
                userWalletBalance: parseInt(updatedWallet.balance),
                version: process.env.REACT_APP_VERSION,
              };

              addDoc(
                collection(
                  db,
                  process.env.REACT_APP_LIVE_STREMING_COLLECTION_NAME,
                  props.liveData?.liveStrimingDocId,
                  process.env.REACT_APP_WHO_JOIN_LIVE_COLLECTION_NAME
                ),
                liveJoinUser
              );
            }
            // joinChannel("audience");
            let comment =
              props.liveData?.enableCommentsInLiveStreaming ?? false;
            navigate("/livestrmingpage", {
              state: {
                role: "audience",
                strimingid: props.liveData?.liveStrimingDocId,
                accessToken: props.liveData?.accessToken,
                commentsFlag: comment,
                gemsForUserComment: props.liveData?.gemForUserComment,
              },
            });

            setOpen({ open: false, val: "" });
          }
        });
      });
    } else {
      setOpen({ open: true, val: props.liveData });
    }
  };

  return (
    <>
      <Box
        style={{
          backgroundColor: "var(--pink)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <Button
          variant="contained"
          className="livebtn text-shadow-typography"
          style={{
            marginTop: "62px",
            backgroundColor: "var(--pinkDark)",
            textTransform: "none",
            width: "90vw",
          }}
          onClick={() => {
            handleprejoinqueries(props.liveData);
          }}
        >
          {process.env.REACT_APP_LIVE_BUTTON}
        </Button>
      </Box>
      <img
        src={LiveImage}
        style={{ height: "-webkit-fill-available", width: "100vw" }}
      />
      {open.open && (
        <ModalforPurchase
          open={open.open}
          val={open}
          wallet={wallet}
          close={() => handleClose()}
          handlePurchase={(ele) => handlePurchase(ele)}
        />
      )}
    </>
  );
};

export default LiveStrimingPage;

import React, { useEffect, useState } from "react";
import AgoraRTC from "agora-rtc-sdk-ng";
import { useLocation, useNavigate } from "react-router-dom";
import CallEndIcon from "@mui/icons-material/CallEnd";
import MicIcon from "@mui/icons-material/Mic";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import FlipCameraIosIcon from "@mui/icons-material/FlipCameraIos";
import logo from "../../assests/logo.png";
import eye from "../../assests/eye.png";
import "../../User/UserLive/AudienceSideLive.scss";
import Timer from "./Timer";
import {
  addDoc,
  collection,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  startAfter,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebaseConfig/firebaseConfig";
import { DisplayImage, GifsData } from "../../configs/gifAssests";
import jems from "../../component/Images/jems.png";
import InfiniteScroll from "react-infinite-scroll-component";
import { MicOff } from "@mui/icons-material";

var rtc = {
  client: null,
  joined: false,
  published: false,
  localStream: null,
  remoteStreams: [],
  params: {},
};

function ModalLiveStrimingPage(props) {
  const [mute, setMute] = useState(false);
  const [open, setOpen] = useState(false);
  const [totalViewCount, setTotalViewCount] = useState(0);
  const [liveChatGifs, setLiveChatGifs] = useState([]);
  const [liveChat, setLiveChat] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);
  const [time, setTime] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  console.log("sdfsadas", location.state.liveData);

  // Options for joining a channel
  var option = {
    appID: process.env.REACT_APP_AGORA_APP_ID,
    channel: process.env.REACT_APP_AGORA_CHANNEL_NAME,
    uid: null,
    token: location.state.liveData.accessToken,
    key: "",
    secret: "",
    role: "",
  };
  console.log("option", option);
  async function joinChannel(role) {
    // Create a client
    rtc.client = AgoraRTC.createClient({ mode: "live", codec: "h264" });

    rtc.client.on("user-published", async (user, mediaType) => {
      if (mediaType === "video") {
        const remoteVideoStream = await rtc.client.subscribe(user, mediaType);
        remoteVideoStream.play("local_stream_");
      }
    });

    rtc.client.on("user-unpublished", async (user) => {
      const remoteVideoElement = document.getElementById(
        `local_stream_${user.uid}`
      );
      if (remoteVideoElement) {
        remoteVideoElement.innerHTML = "";
      }
    });

    rtc.client.on("connection-state-change", (currentState, prevState) => {
      console.log("Connection state changed:", currentState, prevState);

      if (currentState === "DISCONNECTED") {
      }
    });

    try {
      await rtc.client.join(option.appID, option.channel, option.token, null);
    } catch (err) {
      console.error("Error joining channel:", err);
      return;
    }

    rtc.params.uid = rtc.client.uid;

    if (role === "host") {
      rtc.client.setClientRole("host");

      // Create a local audio track (but don't play it immediately)

      rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
      rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
      await rtc.client.publish([rtc.localAudioTrack, rtc.localVideoTrack]);

      rtc.client.on("user-published", async (user, mediaType) => {
        if (mediaType === "video") {
          const remoteVideoTrack = user.videoTrack;
          const remoteContainer = document.getElementById("local_stream_"); // Create a container for remote streams
          remoteVideoTrack.play(remoteContainer);
        }
      });

      const localVideoContainer = document.getElementById("local_stream_");
      rtc.localVideoTrack.play(localVideoContainer);
      // rtc.localAudioTrack.play();

      rtc.client.on("connection-state-change", function (evt) {
        console.log("audience", evt);
      });
    }
  }
  useEffect(() => {
    joinChannel("host");
  }, []);

  useEffect(() => {
    let q2 = query(
      collection(
        db,
        process.env.REACT_APP_LIVE_STREMING_COLLECTION_NAME,
        location.state?.liveData?.liveStrimingDocId,
        process.env.REACT_APP_WHO_JOIN_LIVE_COLLECTION_NAME
      ),
      where("liveJoinStatus", "==", "join")
    );
    onSnapshot(q2, (querySnapshot) => {
      let liveJoinUsersArr = [];
      querySnapshot.forEach((doc) => {
        liveJoinUsersArr.push(doc.data());
      });

      setTotalViewCount(liveJoinUsersArr.length);
    });
    getChatsMessages();
    getGifsUsersSend();
  }, []);

  const getGifsUsersSend = () => {
    let q6 = query(
      collection(
        db,
        process.env.REACT_APP_LIVE_STREMING_COLLECTION_NAME,
        location.state?.liveData?.liveStrimingDocId,
        process.env.REACT_APP_LIVE_CHAT_GIFS_COLLECTION_NAME
      ),
      orderBy("sentOn", "desc")
    );
    onSnapshot(q6, (querySnapshot) => {
      console.log("hi");
      let gifsofUsers = [];
      querySnapshot.forEach((doc) => {
        gifsofUsers.push(doc.data());
      });

      setLiveChatGifs(gifsofUsers);
      console.log("gifsofUsers", gifsofUsers);
    });
  };

  const getChatsMessages = (lastDoc = null) => {
    let q3;
    if (lastDoc) {
      q3 = query(
        collection(
          db,
          process.env.REACT_APP_LIVE_STREMING_COLLECTION_NAME,
          location.state?.liveData?.liveStrimingDocId,
          process.env.REACT_APP_LIVE_CHAT_MESSAGES_COLLECTION_NAME
        ),
        orderBy("createdAt", "desc"),
        startAfter(lastDoc),
        limit(15)
      );
    } else {
      q3 = query(
        collection(
          db,
          process.env.REACT_APP_LIVE_STREMING_COLLECTION_NAME,
          location.state?.liveData?.liveStrimingDocId,
          process.env.REACT_APP_LIVE_CHAT_MESSAGES_COLLECTION_NAME
        ),
        orderBy("createdAt", "desc"),
        limit(15)
      );
    }
    onSnapshot(q3, (querySnapshot) => {
      let chats = [];
      setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
      querySnapshot.forEach((doc) => {
        chats.push(doc.data());
      });
      chats.reverse();
      setLiveChat([...chats, ...liveChat]);
    });
  };

  const handleMute = () => {
    if (rtc.localAudioTrack) {
      if (!mute) {
        rtc.localAudioTrack.setEnabled(false);
        setMute(true);
      } else {
        rtc.localAudioTrack.setEnabled(true);
        setMute(false);
      }
    }
  };

  const handleLiveStrimingEnd = () => {
    console.log("users", totalViewCount);
    console.log("gifs", liveChatGifs);
    let gifRevenue = 0;
    liveChatGifs.forEach((gif) => {
      gifRevenue = gifRevenue + parseInt(gif.noOfGems);
    });
    let liveend = {
      liveEndOn: serverTimestamp(),
      liveGiphyRevenue: gifRevenue,
      liveUserJoinRevenue:
        parseInt(totalViewCount) * parseInt(location.state.liveData.noOfGems),
      noOfGifsReceived: liveChatGifs.length,
      noOfViewers: totalViewCount,
      liveStartedAt: new Date(
        location.state.liveData.createdOn.seconds * 1000 +
          location.state.liveData.createdOn.nanoseconds / 1000000
      ),
      liveDuration: time,
    };
    liveend.liveRevenue = liveend.liveUserJoinRevenue;
    addDoc(
      collection(
        db,
        process.env.REACT_APP_LIVE_STREMING_COLLECTION_NAME,
        location.state?.liveData?.liveStrimingDocId,
        "liveEndWithDetails"
      ),
      liveend
    ).then(() => {
      updateDoc(
        doc(
          db,
          process.env.REACT_APP_LIVE_STREMING_COLLECTION_NAME,
          location.state?.liveData?.liveStrimingDocId
        ),
        {
          liveStatus: false,
          state: "finished",
          liveGiphyRevenue: liveend.liveGiphyRevenue,
          liveRevenue: liveend.liveRevenue,
        }
      ).then(() => {
        if (rtc.localAudioTrack) {
          rtc.localAudioTrack.setEnabled(false);
        }
        if (rtc.localVideoTrack) {
          rtc.localVideoTrack.setEnabled(false);
        }
        console.log(rtc.client, "RTC_CLIENT");
        rtc.client.leave();
        setOpen(false);
        navigate("/a/live");
      });
    });
    console.log("Dsfa", liveend);
  };

  const handleTime = (t) => {
    setTime(t);
  };

  return (
    <div>
      {liveChatGifs.length > 0 && (
        <Box
          className="gif-box"
          style={{
            display: "flex",
            position: "fixed",
            top: "65px",
            overflow: "scroll",
            left: "0px",
            width: "100vw",
            zIndex: "500",
            padding: "10px",
          }}
        >
          {liveChatGifs.map((ele) => {
            let gifSrc;
            let timeString = `${ele.sentOn?.toDate().toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })}`;
            GifsData.map((gif) => {
              if (gif.name === `${ele.giphyName}.jpg`) {
                gifSrc = gif;
              }
            });
            return (
              <div
                style={{
                  width: "15vw",
                  minWidth: "15vw",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "20px",
                }}
              >
                <DisplayImage
                  src={gifSrc}
                  style={{
                    width: "inherit",
                    aspectRatio: "1/1",
                    borderRadius: "50%",
                    overflow: "hidden",
                  }}
                />
                <Typography className="text-align-center font-size text-shadow-typography">
                  {ele.senderName}
                </Typography>
                <Typography className="text-align-center font-size text-shadow-typography">
                  {timeString}
                </Typography>
              </div>
            );
          })}
        </Box>
      )}
      {liveChat.length > 0 && (
        <Box
          id="chatBox1"
          className="chatBox"
          style={{
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
          }}
        >
          <InfiniteScroll
            dataLength={liveChat.length}
            next={() => {
              getChatsMessages(lastDoc);
            }}
            style={{ display: "flex", flexDirection: "column" }}
            scrollableTarget="chatBox1"
            hasMore={true}
            scrollThreshold={"300px"}
            initialScrollY={0}
          >
            {liveChat &&
              liveChat.map((ele) => {
                return (
                  <>
                    <div
                      className="chatmessages"
                      style={{
                        display: "flex",
                        width: "calc(100vw - 10px)",
                        paddingLeft: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <img
                        className="input-field-shadow"
                        src={ele.urlAvatar}
                        style={{
                          width: "40px",
                          aspectRatio: "1/1",
                          height: "40px",
                          borderRadius: "50%",
                          border: "1px solid var(--white)",
                        }}
                      />
                      <Box style={{ marginLeft: "10px" }}>
                        <Typography className="text-shadow-typography chat-font-size">
                          {ele.name}
                        </Typography>
                        <Typography className="text-shadow-typography chat-font-size">
                          {ele.chatMessage}
                        </Typography>
                      </Box>
                    </div>
                  </>
                );
              })}
          </InfiniteScroll>
        </Box>
      )}
      <Box className="header" style={{ backgroundColor: "var(--pink)" }}>
        <Grid container className="headbox center-content">
          <Grid
            item
            xs={3}
            style={{
              color: "var(--white)",
              fontFamily: "var(--font)",
              flexDirection: "column",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span style={{ fontFamily: "var(-font)" }}>Live Since</span>
            <Timer time={(tim) => handleTime(tim)} />
          </Grid>
          <Grid item xs={6} className="center-content">
            <img alt="" src={logo} className="adminLogo" />
          </Grid>
          <Grid
            item
            xs={3}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <img
              alt=""
              src={eye}
              className="adminLogo"
              style={{ width: "30px", height: "30px", aspectRatio: "1/1" }}
            />
            <Typography>{totalViewCount}</Typography>
          </Grid>
        </Grid>
        <Box className="cmline"></Box>
      </Box>
      <Box
        style={{
          zIndex: "5000",
          position: "fixed",
          bottom: "30px",
          width: "60vw",
          display: "flex",
          justifyContent: "space-evenly",
          paddingLeft: "20vw",
          paddingRight: "20vw",
          alignItems: "center",
          left: "0px",
        }}
      >
        {!mute ? (
          <MicIcon
            className="call-icon input-field-shadow"
            style={{
              backgroundColor: "var(--white)",
              padding: "10px",
              borderRadius: "50%",
              color: "var(--blueLight)",
            }}
            onClick={() => handleMute()}
          />
        ) : (
          <MicOff
            className="call-icon input-field-shadow"
            style={{
              backgroundColor: "var(--blueLight)",
              padding: "10px",
              borderRadius: "50%",
              color: "var(--white)",
            }}
            onClick={() => handleMute()}
          />
        )}
        {/* <FlipCameraIosIcon
          className="call-icon input-field-shadow"
          style={{
            backgroundColor: "var(--white)",
            padding: "10px",
            color: "var(--blueLight)",
            borderRadius: "50%",
          }}
        /> */}
        <CallEndIcon
          className="call-icon input-field-shadow"
          style={{
            backgroundColor: "var(--red)",
            padding: "10px",
            borderRadius: "50%",
          }}
          onClick={() => setOpen(true)}
        />
      </Box>
      <div
        id="local_stream_"
        className="local_stream_"
        style={{
          width: "100vw",
          maxHeight: "calc(100vh - 57.33px)",
          height: "calc(100vh - 57.33px)",
        }}
      ></div>
      {open && (
        <Dialog
          open={open}
          aria-labelledby="responsive-dialog-title "
          className="loguotpop"
        >
          <DialogTitle className="dialog-title-background typography-loginpage">
            {process.env.REACT_APP_EXIT_DIALOG_NAME}
          </DialogTitle>

          <DialogContent>
            <DialogContentText className="typography-loginpage">
              Are you sure you want to end live session?
            </DialogContentText>

            <Button
              className="typography-loginpage loginpage-button"
              onClick={() => handleLiveStrimingEnd()}
            >
              {process.env.REACT_APP_YES_BUTTON1}
            </Button>
            <Button
              className="typography-loginpage loginpage-button"
              onClick={() => setOpen(false)}
            >
              {process.env.REACT_APP_NO_BUTTON}
            </Button>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

export default ModalLiveStrimingPage;

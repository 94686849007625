import {
  Box,
  Button,
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import "./Register.scss";
import React, { useEffect, useState } from "react";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from "moment";
import "./Register.scss";
import { useNavigate } from "react-router";
import ModalComponent from "../ModalComponent/ModalComponent";
import { authentication, db } from "../../firebaseConfig/firebaseConfig";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  fetchSignInMethodsForEmail,
  EmailAuthProvider,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { FlagIcon } from "react-flag-kit";
import Country1 from "../Login/Countycode";
import Spinners from "../Spinner/Spinners";
import Fullpopup from "../../User/Settingpage/Fullpopup";

const Register = () => {
  const [search, setSearch] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("+91");
  const navigate = useNavigate();
  const auth = getAuth();
  const [data, setData] = useState({
    Name: "",
    InstagramId: "",
    MobileNumber: "",
    password: "",
    EmailAddress: "",
    DateOfBirth: null,
    TermsAndConditon: "",
  });
  var now = dayjs();

  const [text, setText] = useState("");
  const [open, setOpen] = useState(false);
  const [ddd, setDDD] = useState(false);
  const [opencal, setOpencal] = useState(false);
  const [counter, setCounter] = useState(0);
  const [count, setCount] = useState(60);
  const [OTP, setOTP] = useState("");
  const [editDetails, setEditDetails] = useState(0);
  const [editDetails2, setEditDetails2] = useState(0);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmResultHook, setConfirmResultHook] = useState("");
  const [appVerifier, setAppVerifier] = useState(null);

  useEffect(() => {
    // if (!window.recaptchaVerifier) {
    //   window.recaptchaVerifier = new RecaptchaVerifier(
    //     "recaptcha-container",
    //     {
    //       size: "invisible",
    //       callback: (response) => {},
    //       "expired-callback": () => {},
    //     },
    //     authentication
    //   );
    // }
  }, [editDetails, editDetails2]);

  if (count > 0 && counter > 0) {
    setTimeout(() => {
      setCount(count - 1);
    }, 1000);
  }
  const closeable = () => {
    setModalOpen(false);
  };
  // const proceed = () => {
  //   if (OTP.trim() == "") {
  //     setOpen(true);
  //     setText(process.env.REACT_APP_OTP_CODE_MODAL_TEXT);
  //   } else if (OTP.trim().length !== 6) {
  //     setOpen(true);
  //     setText(process.env.REACT_APP_INVALID_OTP_CODE_MODAL_TEXT);
  //   } else if (count > 0) {
  //     setLoading(true);
  //     confirmResultHook
  //       .confirm(OTP)
  //       .then(async (result) => {})
  //       .catch((error) => {
  //         setLoading(false);
  //         setOpen(true);
  //         setText(process.env.REACT_APP_INVALID_OTP);
  //       });
  //   } else {
  //     setText(process.env.REACT_APP_TIME_EXCEEDS_MODAL_TEXT);
  //     setOpen(true);
  //   }
  // };
  let age = dayjs().format("YYYY") - data.DateOfBirth?.format("YYYY");
  const verifyFields = () => {
    localStorage.clear();
    if (data.Name.trim() == "") {
      setText(process.env.REACT_APP_PLEASE_ENTER_NAME_TEXT);
      setOpen(true);
    } else if (data.MobileNumber.trim() == "") {
      setText(process.env.REACT_APP_MOBILE_VALIDATION_TEXT_MODAL);
      setOpen(true);
    } else if (data.MobileNumber.length < 8 || data.MobileNumber.length > 16) {
      setText(
        process.env.REACT_APP_MODILE_NUMBER_VALIDATION_TEXT_FOR_MOBILE_NUMBER
      );
      setOpen(true);
    } else if (data.EmailAddress == "") {
      setText(process.env.REACT_APP_ENTER_EMAIL_MODAL_TEXT);
      setOpen(true);
    } else if (data.password == "") {
      setText(process.env.REACT_APP_ENTER_PASSWORD_MODAL_TEXT);
      setOpen(true);
    } else if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
        data.EmailAddress
      )
    ) {
      setText(process.env.REACT_APP_INVALID_EMAIL_MODAL_TEXT);
      setOpen(true);
    } else if (data.DateOfBirth === null) {
      setText(process.env.REACT_APP_PLEASE_ENTER_DOB_TEXT);
      setOpen(true);
    } else if (data.TermsAndConditon == false) {
      setText(process.env.REACT_APP_PLEASE_SELECT_TERMS_CONDITON_TEXT);
      setOpen(true);
    } else {
      fetchSignInMethodsForEmail(auth, data.EmailAddress)
        .then((signInMethods) => {
          if (
            signInMethods.indexOf(
              EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD
            ) !== -1
          ) {
            console.log("Email already exists");
            setText(process.env.REACT_APP_EMAIL_ALREADY_EXISTS_TEXT);
            setOpen(true);
          } else {
            createUserWithEmailAndPassword(
              auth,
              data.EmailAddress,
              data.password
            ).then(async (userCredential) => {
              const user = userCredential.user;
              const mobileNumber = `${selectedCountry}${data.MobileNumber.trim()}`;
              const registerObj = {
                blocked: false,
                dob: data.DateOfBirth?.format("DD/MM/YYYY"),
                email: data.EmailAddress,
                idUser: user.uid,
                instagramId: data.InstagramId,
                lastMessageTime: new Date(),
                // lastMessageTime: serverTimestamp(),
                mobile: mobileNumber,
                name: data.Name,
                notificationStatus: true,
                searchKey: data.Name.charAt(0),
                urlAvatar: `${process.env.REACT_APP_IMAGE_GET_CLOUDFAIR_URL1}6769b712-0f0e-48c3-0db2-db9ebd2c2900/public`,
              };
              const walletObj = {
                balance: parseInt(process.env.REACT_APP_INITIAL_WALLET_BALANCE),
                dp: `${process.env.REACT_APP_IMAGE_GET_CLOUDFAIR_URL1}6769b712-0f0e-48c3-0db2-db9ebd2c2900/public`,
                email: data.EmailAddress,
                lastRechargeDate: serverTimestamp(),
                mobile: mobileNumber,
                name: data.Name,
                noOfGemsperChat: parseInt(
                  process.env.REACT_APP_INITIAL_NOOFGEMS_PER_CHAT
                ),
                totalPurchasedGems: parseInt(
                  process.env.REACT_APP_INITIAL_TOTAL_PURCHASED_GEMS
                ),
                uid: user.uid,
              };
              const giftGemsObj = {
                gemsGiftedOn: serverTimestamp(),
                noOfGemsGifted: process.env.REACT_APP_INITIAL_GIFTED_GEMS,
                uid: user.uid,
              };

              const oneYearAgo = moment().subtract(2, "year");

              const newChatsObj = {
                dpUrl: `${process.env.REACT_APP_IMAGE_GET_CLOUDFAIR_URL1}6769b712-0f0e-48c3-0db2-db9ebd2c2900/public`,
                email: data.EmailAddress,
                fcmDeviceToken: "",
                instagramId: data.InstagramId,
                lastChatMessage: process.env.REACT_APP_INITIAL_MESSAGE,
                lastMessageFromUser: false,
                lastMessageTime: oneYearAgo.toDate(),
                name: data.Name,
                totalUnreadCount: parseInt(
                  process.env.REACT_APP_INITIAL_MESSAGE_READ_COUNT
                ),
              };

              const deviceToken = {
                deviceToken: JSON.parse(
                  localStorage.getItem(
                    process.env.REACT_APP_DEVICETOKEN_LOCALSTORAGE
                  )
                ),
                fcmTokenTime: serverTimestamp(),
                localAppVersionOfTheUser: process.env.REACT_APP_VERSION,
                platform: process.env.REACT_APP_PLATFORM,
                uid: user.uid,
              };
              await setDoc(
                doc(
                  db,
                  process.env.REACT_APP_USERS_COLLECTION_NAME,
                  `${user.uid}`
                ),
                registerObj
              ).then(() => {
                localStorage.setItem(
                  process.env.REACT_APP_USERS_LOCALSTORAGE,
                  JSON.stringify(registerObj)
                );
                localStorage.setItem(
                  process.env.REACT_APP_PURCHASEDPHOTO_LOCALSTORAGE,
                  JSON.stringify([])
                );
                localStorage.setItem(
                  process.env.REACT_APP_PURCHASEDVIDEOS_LOCALSTORAGE,
                  JSON.stringify([])
                );
              });
              await setDoc(
                doc(
                  db,
                  process.env.REACT_APP_WALLET_COLLECTION_NAME,
                  `${user.uid}`
                ),
                walletObj
              ).then(() => {
                localStorage.setItem(
                  process.env.REACT_APP_WALLET_LOCALSTORAGE,
                  JSON.stringify(walletObj)
                );
              });
              await setDoc(
                doc(
                  db,
                  process.env
                    .REACT_APP_TRACK_GIFTED_GEMS_FOR_THE_USE_COLLECTION_NAME,
                  `${user.uid}`
                ),
                giftGemsObj
              ).then(() => {});
              await setDoc(
                doc(
                  db,
                  process.env.REACT_APP_NEW_CHATS_COLLECTION,
                  `${user.uid}`
                ),
                newChatsObj
              ).then(() => {});
              await setDoc(
                doc(
                  db,
                  process.env.REACT_APP_HELP_AND_SUPPORT_CHAT_COLLECTION_NAME,
                  `${user.uid}`
                ),
                newChatsObj
              ).then(() => {});
              await addDoc(
                collection(db, process.env.REACT_APP_DEVICETOKEN_COLLECTION),
                deviceToken
              ).then(() => {
                navigate("/userhomepage/home");
                setLoading(false);
              });
            });
          }
        })
        .catch((error) => {
          setText(error.message);
          setOpen(true);
          console.error("Error creating user: ", error);
        });
    }
  };
  const SendOTP = () => {
    setConfirmResultHook("");

    const mobileNumber = `${selectedCountry}${data.MobileNumber.trim()}`;
    // const appVerifier = window.recaptchaVerifier;
    let tempAppVerifier = null;
    if (!appVerifier) {
      tempAppVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {},
          "expired-callback": () => {},
        },
        authentication
      );
    } else {
      tempAppVerifier = appVerifier;
    }
    signInWithPhoneNumber(authentication, mobileNumber, tempAppVerifier)
      .then((confirmationResult) => {
        if (!appVerifier) {
          setAppVerifier(tempAppVerifier);
        }
        setConfirmResultHook(confirmationResult);

        setCounter(counter + 1);
      })
      .catch((error) => {
        // window.recaptchaVerifier.recaptcha.reset();
        // window.recaptchaVerifier.clear();
        setOpen(true);
        setText(error.message);
      });
  };

  const close = () => {
    setOpen(false);
  };

  return (
    <Box className="rr">
      {loading ? <Spinners /> : null}
      {counter === 0 ? (
        <>
          <TextField
            placeholder={process.env.REACT_APP_NAME_PLACEHOLDER}
            variant="outlined"
            className="textfield"
            focused={false}
            value={data.Name}
            onChange={(e) => setData({ ...data, Name: e.target.value })}
          />
          <TextField
            placeholder={process.env.REACT_APP_INSTAID_PLACEHOLDER}
            variant="outlined"
            className="textfield"
            focused={false}
            value={data.InstagramId}
            onChange={(e) => setData({ ...data, InstagramId: e.target.value })}
          />
          <Box sx={{ display: "flex", width: "90%" }}>
            <FormControl className="selectfield">
              <Select
                labelId="demo-simple-select-label"
                renderValue={(selectedCountry) => selectedCountry}
                id="demo-simple-select"
                className="selectcode"
                value={selectedCountry}
                onChange={(e) => {
                  setSelectedCountry(e.target.value);
                }}
                MenuProps={{ autoFocus: false }}
              >
                <Box sx={{ padding: "0px 14px" }}>
                  <Typography variant="h6">
                    {process.env.REACT_APP_SELECT_HEADING}
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    variant="standard"
                    label="Search..."
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                  ></TextField>
                </Box>
                {Country1.filter((val) => {
                  if (search === "") {
                    return val;
                  } else if (
                    val.name.toLowerCase().includes(search.toLowerCase()) ||
                    val.code.toLowerCase().includes(search.toLowerCase()) ||
                    val.cca2.toLowerCase().includes(search.toLowerCase())
                  ) {
                    return val;
                  }
                }).map((ev) => {
                  return (
                    <MenuItem value={ev.code} className="menucountry">
                      <FlagIcon code={ev.flag} />
                      <span id="countryname">
                        {ev.name}
                        &nbsp;({ev.cca2})
                      </span>
                      <span className="ccode">{ev.code}</span>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <TextField
              placeholder={
                process.env.REACT_APP_ENTER_MOBILE_NUMBER_PLACEHOLDER
              }
              variant="outlined"
              className="textfield"
              focused={false}
              value={data.MobileNumber}
              sx={{ marginLeft: "5px" }}
              type="tel"
              onChange={(e) =>
                setData({ ...data, MobileNumber: e.target.value })
              }
              inputProps={{ maxLength: 15 }}
            />
          </Box>

          <TextField
            placeholder={process.env.REACT_APP_ENTER_EMAIL_ID_PLACEHOLDER}
            variant="outlined"
            className="textfield"
            type="email"
            focused={false}
            value={data.EmailAddress}
            onChange={(e) => setData({ ...data, EmailAddress: e.target.value })}
          />
          <TextField
            placeholder="Password"
            // placeholder={process.env.REACT_APP_ENTER_EMAIL_ID_PLACEHOLDER}
            variant="outlined"
            className="textfield"
            type="password"
            focused={false}
            value={data.password}
            onChange={(e) => setData({ ...data, password: e.target.value })}
          />
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            {ddd ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => setOpencal(!opencal)}
              >
                <TextField
                  variant="outlined"
                  focused={false}
                  className="textfield"
                  value={data.DateOfBirth?.format("DD-MM-YYYY")}
                  // disabled
                />
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => setOpencal(!opencal)}
              >
                <TextField
                  variant="outlined"
                  focused={false}
                  style={{ color: "var(--white)", fontFamily: "var(--font)" }}
                  placeholder={process.env.REACT_APP_ENTER_DOB_PLACEHOLDER}
                  className="textfield"
                  // disabled
                />
              </div>
            )}
            {opencal === true ? (
              <LocalizationProvider dateAdapter={AdapterDayjs} id="caldiv">
                <DemoItem>
                  <Box className="calhead">
                    <Typography
                      variant="body1"
                      style={{ color: "var(--black)" }}
                    >
                      {process.env.REACT_APP_SELECTED_DATE_HEADING}
                    </Typography>
                    <Typography variant="body1">
                      {data.DateOfBirth?.format("YYYY")}
                    </Typography>
                    <Typography variant="h4">
                      {data.DateOfBirth?.format("ddd")},
                      {data.DateOfBirth?.format("MMM")}{" "}
                      {data.DateOfBirth?.format("DD")}
                    </Typography>
                  </Box>
                  <DateCalendar
                    defaultValue={dayjs()}
                    disableFuture={true}
                    onChange={(e) => {
                      setData({ ...data, DateOfBirth: dayjs(e) });
                      setDDD(!ddd);
                    }}
                  />
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button variant="text" onClick={() => setOpencal(!opencal)}>
                      {process.env.REACT_APP_MODAL_CANCEL_BUTTON_TEXT}
                    </Button>
                    <Button
                      variant="text"
                      onClick={() => {
                        if (age <= 18) {
                          setText(process.env.REACT_APP_ENTER_INVALID_AGE_TEXT);
                          setOpen(true);
                        } else {
                          setOpencal(!opencal);
                        }
                      }}
                    >
                      {process.env.REACT_APP_OK_BUTTON}
                    </Button>
                  </Box>
                </DemoItem>
              </LocalizationProvider>
            ) : (
              ""
            )}
          </Box>
          <Box className="terms-and-condition text-align-center">
            <Typography
              style={{ fontFamily: "var(--font)" }}
              variant="body2"
              id="rgchek"
            >
              <Checkbox
                onChange={(e) =>
                  setData({ ...data, TermsAndConditon: e.target.checked })
                }
                checked={data.TermsAndConditon}
              />
              &nbsp;{process.env.REACT_APP_TERMS_AND_CONDITION_LINK_TEXT1}{" "}
              <a href="#" onClick={() => setModalOpen(true)}>
                {process.env.REACT_APP_TERMS_AND_CONDITION_LINK_TEXT}
              </a>
            </Typography>
          </Box>
          <Button
            variant="contained"
            className="register-screen-button typography-loginpage loginpage-button button-color"
            color="primary"
            onClick={() => {
              verifyFields();
            }}
          >
            {/* {process.env.REACT_APP_SEND_OTP_BUTTON} */}
            {process.env.REACT_APP_PROCEED_BUTTON}
          </Button>
          <Button
            variant="contained"
            className="register-screen-button typography-loginpage loginpage-button button-color"
            color="primary"
            onClick={() => navigate("/login")}
          >
            {process.env.REACT_APP_BACK_TO_LOGIN_BUTTON}
          </Button>
        </>
      ) : (
        <>
          <Typography className="upper-text">
            {process.env.REACT_APP_VERIFICATION_CODE_UPPER_TEXT}
          </Typography>
          <Typography className="upper-text">{data.MobileNumber}</Typography>
          <Typography className="upper-text upper-text1">
            {process.env.REACT_APP_VERIFICATION_CODE_UPPER_TEXT1}
          </Typography>
          <TextField
            placeholder="Enter OTP"
            variant="outlined"
            className="textfield"
            inputProps={{ maxLength: 6 }}
            onChange={(e) => {
              setOTP(e.target.value);
            }}
            type="number"
            value={OTP}
            focused={false}
          />

          <Button
            variant="contained"
            className="register-screen-button typography-loginpage loginpage-button button-color"
            color="primary"
            onClick={() => {
              setCounter(0);
              setEditDetails(editDetails + 1);
            }}
          >
            {process.env.REACT_APP_EDIT_DETAILS_BUTTON}
          </Button>
          <ModalComponent
            close={close}
            open={open}
            text={text}
            title={process.env.REACT_APP_DIALOG_APP_NAME}
          />
        </>
      )}
      <div id="recaptcha-container" />
      <ModalComponent
        close={close}
        open={open}
        text={text}
        title={process.env.REACT_APP_DIALOG_APP_NAME}
      />

      <Fullpopup
        close={() => closeable()}
        open={modalOpen}
        text={"Terms-Condition"}
      />
    </Box>
  );
};

export default Register;
